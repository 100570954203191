<div class="site-page-header-ghost-wrapper">
  <nz-page-header [nzGhost]="false" style="background-color: #f896e3; border-top-right-radius: 10px; border-top-left-radius: 10px;">
    <nz-page-header-title>Хувиарлаагүй ачаанууд</nz-page-header-title>
    <!-- <nz-page-header-subtitle>This is a subtitle</nz-page-header-subtitle> -->
    <nz-page-header-extra style="display: flex;/* justify-content: flex-end; */">
      <button nz-button nzType="primary" nzShape="round" routerLink="/achaahuv" class="round">Ачаа хувиарлах<i nz-icon [nzType]="'arrow-right'"></i></button>

    </nz-page-header-extra>

  </nz-page-header>
</div>

<div class="inner-content">
  <div nz-row [nzGutter]="{ xs: 4, sm: 6, md: 8, lg: 10 }">
    <div nz-col class="gutter-row" [nzSpan]="6"><div class="inner-box">
      <form nz-form nzLayout="vertical">
      <nz-form-item>
        <nz-form-label >Бар код</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input type="text" nz-input placeholder="Бар кодоор хайх" />
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button nz-button nzType="primary" (click)="rstDteRnge()"><i nz-icon nzType="search"></i></button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      </form>
    </div>
  </div>
    <div nz-col class="gutter-row" [nzSpan]="6"><div class="inner-box">
      <form nz-form nzLayout="vertical">
      <nz-form-item>
        <nz-form-label >Утас</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input type="text" nz-input placeholder="Утасны дугаараар хайх" />
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button nz-button nzType="primary" (click)="rstDteRnge()"><i nz-icon nzType="search"></i></button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      </form>
    </div>
  </div>
    <div nz-col class="gutter-row" [nzSpan]="5"><div class="inner-box">
      <form nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label >Огноо</nz-form-label>
          <!-- <nz-range-picker [nzFormat]="dateFormat" (nzOnCalendarChange)="daterng($event)"></nz-range-picker> -->

          <div nz-form nzLayout="inline">
            <nz-form-item style="display: inline-block;">
              <nz-date-picker
              [nzDisabledDate]="disabledStartDate"
              nzShowTime='false'
              nzFormat="yyyy/MM/dd"
              [(ngModel)]="startValue"
              nzPlaceHolder="Эхлэх"
              (nzOnOpenChange)="slctDternge()"
              style="width: 135px;"
              [ngModelOptions]="{standalone: true}">
          </nz-date-picker>
            <nz-date-picker
              #endDatePicker
              [nzDisabledDate]="disabledEndDate"
              nzShowTime="false"
              nzFormat="yyyy/MM/dd"
              [(ngModel)]="endValue"
              nzPlaceHolder="Сүүл"
              (nzOnOpenChange)="slctDternge()"
              style="width: 135px;"
              [ngModelOptions]="{standalone: true}"

            ></nz-date-picker>
            </nz-form-item>

          </div>



        </nz-form-item>
        </form>
      </div>
    </div>
    <div nz-col class="gutter-row" [nzSpan]="5">
      <div class="inner-box">
      <form nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label>Бүртгэлийн ажилтан</nz-form-label>
          <nz-form-control>
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Ажилтан сонгох">
              <nz-option>ыбөыбөыб</nz-option>
              <nz-option>ыбөыбөыб</nz-option>
              <nz-option>ыбөыбөыб</nz-option>
              <nz-option>ыбөыбөыб</nz-option>
              <nz-option>ыбөыбөыб</nz-option>

            </nz-select>
          </nz-form-control>
        </nz-form-item>
        </form>
      </div>
    </div>
    <div nz-col class="gutter-row" [nzSpan]="2">
      <div class="inner-box">
      <form nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label></nz-form-label>
          <nz-button-group [nzSize]="'default'">
            <button nz-button nzType="primary" nz-tooltip nzTooltipTitle="PDF татах"><i nz-icon nzType="file-pdf"></i></button>
            <button nz-button nzType="primary" nz-tooltip nzTooltipTitle="Excel татах"><i nz-icon nzType="file-excel"></i></button>
          </nz-button-group>
        </nz-form-item>
        </form>
      </div>
    </div>




  </div>
  <nz-table #ilgemjlst1 [nzData]="ilgemjlst" nzSize="small" nzTableLayout="auto" [nzFrontPagination]="true" [nzPageSize]="20">

    <thead>
      <tr>
        <th>#</th>
        <th nzWidth="12%">Утас</th>
        <th nzWidth="20%">Бар код</th>
        <th>Огноо#</th>
        <th nzWidth="18%">Хэмжээ</th>
        <th>Үнэ</th>
        <th>Тайлбар</th>
        <th>Б/ажилтан</th>
        <!-- <th>Төлсөн</th> -->
        <!-- <th>Үлдэгдэл</th>
        <th>Ачуулсан</th> -->
        <th></th>

      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of ilgemjlst1.data let i = index">
        <td>{{i+1}}</td>
        <td>{{item.utas}}</td>
        <td>{{item.barcode}}</td>
        <td>{{item.ognoo | date: 'yyyy/MM/dd'}}</td>
        <td>{{item.hemjee}}</td>
        <td>{{ item.price}} </td>
        <td>{{ item.note}} </td>
        <td>{{ item.ner}}</td>
        <td>
          <nz-button-group nzSize="small">
            <button nz-button nzType="primary" class="editbtn"><i nz-icon nzType="form" nz-tooltip nzTooltipTitle="Засварлах"></i></button>
            <button nz-button nzType="primary" class="editbtn"><i nz-icon nzType="delete" nz-tooltip nzTooltipTitle="Устгах"></i></button>
          </nz-button-group>
      </td>
      </tr>
    </tbody>
  </nz-table>
  <div class="spin" *ngIf="!ilgemjlst">
    <nz-spin nzSimple></nz-spin>
  </div>
</div>

<nz-modal
      [(nzVisible)]="VsbleBnk"
      [nzTitle]="modalTitle"
      [nzContent]="modalContent"
      [nzFooter]="modalFooter"
      (nzOnCancel)="handleCancel()"
    >
      <ng-template #modalTitle>Мөнгөн гүйлгээ бүртгэх</ng-template>

      <ng-template #modalContent>


        <form nz-form >
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24">#</nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <div nz-row [nzGutter]="8">
                <div nz-col [nzSpan]="12">
                  <input nz-input name="dugaar" [disabled]="true"/>
                </div>
                <div nz-col [nzSpan]="12">
                  <nz-date-picker nzPlaceHolder="Огноо" name="dte"></nz-date-picker>
                </div>
              </div>

            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24">Гүйлгээ төрөл</nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" >
              <!-- <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Гүйлгээний төрөл сонгох..." [(ngModel)]="bnkDta.bnkaccntid" name="bnktype">
                <nz-option *ngFor="let acc of bnkacclst" nzLabel="{{acc.bnkname}} {{acc.bnknum}}" [nzValue]="acc.bnkid"></nz-option>

              </nz-select> -->
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24">Дүн</nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" >
              <div nz-row [nzGutter]="8">
                <div nz-col [nzSpan]="12">
                  <input nz-input name="dun"  (keypress)="numberOnly($event)" (focus)="selctfunc($event)"/>
                </div>

                <!-- <div nz-col [nzSpan]="12" style="margin-left: -4px; margin-right: 4px; margin-top: 5px; font-style: italic;">
                  {{dunChnge() | number: '1.0-0'}}
                </div> -->


              </div>


            </nz-form-control>

          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSm]="6" [nzXs]="24">Тайлбар</nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" >
              <textarea nz-input name="memo" rows="2" placeholder="Гүйлгээний тайлбар бичих..."></textarea>
            </nz-form-control>
          </nz-form-item>
        </form>

      </ng-template>

      <ng-template #modalFooter>

        <button nz-button nzType="default">Болих</button>
        <button nz-button nzType="primary" >Хадгалах</button>
      </ng-template>
</nz-modal>

<ng-template #tplTitle>
  <span>Ачаа бүртгэх <i nz-icon nzType="plus-circle"></i></span>
</ng-template>
<ng-template #tplContent let-params>
  <div nz-row [nzGutter]="[8, 0]" >
    <div nz-col nzSpan="12" >
      <div nz-form nzLayout="vertical" >
        <nz-form-item >
          <nz-form-label>Бар код</nz-form-label>
          <nz-form-control>
            <input nz-input placeholder="Бар код..." [(ngModel)]="ilgemjDta.barcode" name="barcode" (keypress)="numberOnly($event)" (focus)="selctfunc($event)" autocomplete="off" maxlength="10"  autofocus/>
          </nz-form-control>
        </nz-form-item>
      </div>
      </div>
    <div nz-col nzSpan="12" style="margin-bottom: 0px; margin-top: 0px;">
      <div nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label>Утас</nz-form-label>
          <nz-form-control>
            <input nz-input placeholder="Утас..." [(ngModel)]="ilgemjDta.utas" name="utas"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      </div>
    <div nz-col nzSpan="12">
      <div nz-form nzLayout="vertical">
        <nz-form-item >
          <nz-form-label>Хэмжээ</nz-form-label>
          <nz-form-control>
            <input nz-input placeholder="Хэмжээ..." [(ngModel)]="ilgemjDta.hemjee" name="hemjee"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      </div>
    <div nz-col nzSpan="12">
      <div nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label>Худалдах үнэ</nz-form-label>
          <nz-form-control>
            <input nz-input placeholder="Үнэ..." [(ngModel)]="ilgemjDta.price" name="price"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      </div>
    <div nz-col nzSpan="7" >
      <div nz-form nzLayout="vertical" >
        <nz-form-item>
          <nz-form-label>Огноо</nz-form-label>
          <nz-form-control>
            <nz-date-picker [(ngModel)]="ilgemjDta.ognoo"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      </div>
    <div nz-col nzSpan="17" >
      <div nz-form nzLayout="vertical" >
        <nz-form-item>
          <nz-form-label>Тайлбар</nz-form-label>
          <nz-form-control>
            <input nz-input placeholder="Энэ хэсэгт тайлбар бичнэ..."  name="tailbar"/>
          </nz-form-control>
        </nz-form-item>
      </div>
      </div>

</div>




</ng-template>
<ng-template #tplFooter let-ref="modalRef">
  <div style="float: left"><button nz-button (click)="ref.destroy()"><i nz-icon nzType="poweroff"></i>Хаах</button></div>
  <button nz-button nzType="primary" (click)="hadgalah()" [nzLoading]="btnlding1"><i nz-icon nzType="save"></i>Хадгалах</button>
  <button nz-button nzType="primary" (click)="hadhaah(ref)" [nzLoading]="btnlding"><i nz-icon nzType="save"></i>Хадгалах<i nz-icon nzType="arrow-right"></i>Хаах</button>
</ng-template>





