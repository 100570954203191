<div class="site-page-header-ghost-wrapper">
  <nz-page-header [nzGhost]="false" style="background-color: #e8c4f1; border-top-right-radius: 10px; border-top-left-radius: 10px;">
    <nz-page-header-title>Тавиур жагсаалт</nz-page-header-title>
    <!-- <nz-page-header-subtitle>This is a subtitle</nz-page-header-subtitle> -->
    <nz-page-header-extra style="display: flex;/* justify-content: flex-end; */">
    <button nz-button nzType="primary" nzShape="round" (click)="newTaviur(tplTitle1, tplContent1, tplFooter1)"><i nz-icon [nzType]="'plus'"></i>Шинэ тавиур</button>

    </nz-page-header-extra>

  </nz-page-header>
</div>

<div class="inner-content">
  <div nz-row [nzGutter]="{ xs: 4, sm: 6, md: 8, lg: 10 }">
    <div nz-col class="gutter-row" [nzSpan]="6"><div class="inner-box">
      <form nz-form nzLayout="vertical">
      <nz-form-item>

        <nz-form-control>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input type="text" nz-input placeholder="Тавиур нэрээр хайх" />
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button nz-button nzType="primary" (click)="rstDteRnge()"><i nz-icon nzType="search"></i></button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      </form>
    </div>
  </div>
    <!-- <div nz-col class="gutter-row" [nzSpan]="6"><div class="inner-box">
      <form nz-form nzLayout="vertical">
      <nz-form-item>
        <nz-form-label >Утас</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input type="text" nz-input placeholder="Утасны дугаараар хайх" />
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button nz-button nzType="primary" (click)="rstDteRnge()"><i nz-icon nzType="search"></i></button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      </form>
    </div>
  </div> -->


  </div>
  <nz-table #tavtrnslst1 [nzData]="tavlst" nzSize="small" nzTableLayout="auto" [nzFrontPagination]="true" [nzPageSize]="20">

    <thead>
      <tr>
        <th>#</th>
        <th nzWidth="5%">Тавиур#</th>
        <th nzWidth="20%">Тавиур нэр</th>
        <th nzWidth="12%">Ачаа тоо</th>

        <th nzWidth="25%">Тайлбар</th>
        <!-- <th>Төлсөн</th> -->
        <!-- <th>Үлдэгдэл</th>
        <th>Ачуулсан</th> -->
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of tavtrnslst1.data let i = index">
        <td>{{i+1}}</td>
        <td>{{item.tav_id}}</td>
        <td>{{item.tavnme}}</td>
        <td>{{item.count || 0}}</td>

        <td>{{item.tailbar}}</td>
        <td>
          <nz-button-group nzSize="small">
            <button nz-button nzType="primary" class="editbtn" (click)="huvIlgemj(tplTitle, tplContent, tplFooter, item)"><i nz-icon nzType="form" nz-tooltip nzTooltipTitle="Засварлах"></i></button>
            <button nz-button nzType="primary" class="editbtn"(click)="remTav(item)"><i nz-icon nzType="delete" nz-tooltip nzTooltipTitle="Устгах"></i></button>
          </nz-button-group>
      </td>
      </tr>
    </tbody>
  </nz-table>
  <div class="spin" *ngIf="!tavlst">
    <nz-spin nzSimple></nz-spin>
  </div>
</div>



<ng-template #tplTitle>
  <span style="color: #1890ff;" >Тавиур нэр засварлах </span>
</ng-template>
<ng-template #tplContent let-params>
  <div nz-row [nzGutter]="[8, 0]" >
    <div nz-col nzSpan="12" >
      <div nz-form nzLayout="vertical" >
        <nz-form-item >
          <nz-form-label>Тавиур нэр/Хуучин/</nz-form-label>
          <nz-form-control>
            <input nz-input [(ngModel)]="uptav.oldtavnme" [disabled]="true" name="oldtavnme"/>
          </nz-form-control>
          <!-- <nz-form-label style="margin-top: 4px;">Огноо</nz-form-label> -->
          <nz-form-control style="margin-top: 3px;">
            <nz-form-label>Тавиур нэр/Шинэ/</nz-form-label>
            <input nz-input  [(ngModel)]="uptav.tavnme" name="newtavnme" autofocus/>
          </nz-form-control>
        </nz-form-item>
      </div>
      </div>
    <div nz-col nzSpan="12" style="margin-bottom: 0px; margin-top: 0px;">
      <div nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label >Тайлбар</nz-form-label>
          <nz-form-control>
            <textarea rows="4" nz-input placeholder="Энэ хэсэгт тайлбар бичнэ..." [(ngModel)]="uptav.tailbar" name="note"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
      </div>




</div>




</ng-template>
<ng-template #tplFooter let-ref="modalRef">
  <div style="float: left"><button nz-button (click)="mdldestroy(ref)"><i nz-icon nzType="poweroff"></i>Хаах</button></div>

  <button nz-button nzType="primary" [nzLoading]="btnlding" (click)="hadhaah(ref)"><i nz-icon nzType="save"></i>Хадгалах</button>
</ng-template>


<ng-template #tplTitle1>
  <span style="color: #1890ff;" >Шинэ тавиур </span>
</ng-template>
<ng-template #tplContent1 let-params>
  <div nz-row [nzGutter]="[8, 0]" >
    <div nz-col nzSpan="24" >
      <div nz-form nzLayout="vertical" >
        <nz-form-item >
          <nz-form-control >
            <nz-form-label>Тавиур нэр</nz-form-label>
            <input nz-input  [(ngModel)]="newtav.tavnme" name="newtavnme" autofocus/>
          </nz-form-control>
        </nz-form-item>
      </div>
      </div>
    <div nz-col nzSpan="24" style="margin-bottom: 0px; margin-top: 0px;">
      <div nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label >Тайлбар</nz-form-label>
          <nz-form-control>
            <textarea rows="4" nz-input placeholder="Энэ хэсэгт тайлбар бичнэ..." [(ngModel)]="newtav.tailbar" name="note"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
      </div>




</div>




</ng-template>
<ng-template #tplFooter1 let-ref="modalRef">
  <div style="float: left"><button nz-button (click)="newTavdestroy(ref)"><i nz-icon nzType="poweroff"></i>Хаах</button></div>

  <button nz-button nzType="primary" [nzLoading]="btnlding" (click)="newTavhad(ref)"><i nz-icon nzType="save"></i>Хадгалах</button>
</ng-template>






