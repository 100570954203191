import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpsrvce: HttpClient) {}
  lggedUsr = false;

  // getLoggedUsr() {
  //   if (this.lggedUsr) {
  //     return true;
  //   }
  // }

  newUser(frm: any): Observable<any> {
    const body = JSON.stringify(frm);
    const catoption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    console.log(body);

    return this.httpsrvce.post<any>(
      'https://amarcargoapi.amar.ac/api/user',
      body,
      catoption
    );
  }

  getUser(): Observable<any[]> {
    return this.httpsrvce.get<any[]>('https://amarcargoapi.amar.ac/api/user');
  }

  getUserrol(): Observable<any[]> {
    return this.httpsrvce.get<any[]>(
      'https://amarcargoapi.amar.ac/api/userrol/1'
    );
  }

  upZur(id: number, frm: any): Observable<any> {
    const body = JSON.stringify(frm);
    const catoption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpsrvce.put<any>(
      'https://amarcargoapi.amar.ac/api/userzur/' + id,
      body,
      catoption
    );
  }

  getCmpny(): Observable<any> {
    return this.httpsrvce.get<any>('https://amarcargoapi.amar.ac/api/company');
  }

  upCmpny(frm1: any): Observable<any> {
    const body1 = JSON.stringify(frm1);
    const catoption1 = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.httpsrvce.put<any>(
      'https://amarcargoapi.amar.ac/api/company',
      body1,
      catoption1
    );
  }
}
