import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TavtransService {
  constructor(private httpsrvce: HttpClient) {}

  newTavtrans(frm: any): Observable<any> {
    const body = JSON.stringify(frm);
    const catoption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    console.log(body);
    return this.httpsrvce.post<any>(
      'https://amarcargoapi.amar.ac/api/tavtrans',
      body,
      catoption
    );
  }

  getTavtrans(): Observable<any[]> {
    return this.httpsrvce.get<any[]>(
      'https://amarcargoapi.amar.ac/api/taviurtrans'
    );
  }

  getolgsonids(): Observable<any[]> {
    return this.httpsrvce.get<any[]>('https://amarcargoapi.amar.ac/api/olgids');
  }

  newOlgsontrns(frm: any): Observable<any> {
    console.log(frm);
    const body = JSON.stringify(frm);
    const catoption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    console.log(body);

    return this.httpsrvce.post<any>(
      'https://amarcargoapi.amar.ac/api/addolgson',
      body,
      catoption
    );
  }
}
