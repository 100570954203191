import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ElementRef,
} from '@angular/core';
import { IlgeemjService } from '../services/ilgeemj.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import format from 'date-fns/format';

@Component({
  selector: 'app-ereenburt',
  templateUrl: './ereenburt.component.html',
  styleUrls: ['./ereenburt.component.css'],
})
export class EreenburtComponent implements OnInit {
  ilgemjlst: any;
  VsbleBnk: boolean = false;

  startValue: Date | null = null;
  endValue: Date | null = null;
  btnlding = false;
  btnlding1 = false;

  ilgemjDta: any = {
    barcode: null,
    ognoo: null,
    utas: null,
    price: null,
    hemjee: null,
    userid: 1,
    hemneg: null,
    note: null,
  };

  hemnegj: any = [
    {
      id: 1,
      nme: 'Киллограм',
    },
    {
      id: 2,
      nme: 'Куб мер',
    },
  ];

  btnLoading1 = false;
  btnLoading = false;

  sendEreen: any = {
    hadgalah: 'hadgalah',
    ereen: null,
  };

  constructor(
    private el: ElementRef,
    private ilgemjSrvce: IlgeemjService,
    private modal: NzModalService,
    private notification: NzNotificationService
  ) {}

  ngOnInit(): void {}

  hadhaah(): void {
    this.btnLoading = true;
    if (this.ilgemjlst.length > 0) {
      this.sendEreen.ereen = this.ilgemjlst;
      // this.sendEreen.ereen.forEach((element: any) => {
      //   element.barcode?.toString();
      //   element.note?.toString();
      // });

      this.sendEreen.ereen?.map((elmnt: any) => elmnt.barcode.toString());
      this.ilgemjSrvce.newEreen(this.sendEreen).subscribe((rslt) => {
        // console.log(rslt);
        this.sendEreen.ereen = null;
        this.btnLoading = false;
        this.ilgemjlst = null;

        this.crteNtcn('success', 'Амжилттай хадгалсан.', '');
      });
    }
  }

  crteNtcn(type: string, title: string, message: string): void {
    this.notification.create(type, title, message);
  }

  setfocus(): void {
    (<HTMLInputElement>document.getElementById('barcde')).focus();
  }

  DataFromEventEmitter(event: any): any {
    this.btnLoading1 = true;

    this.ilgemjlst = event
      .filter((rslts: any) => (rslts.barcode ? true : false))
      .map((rslt: any) => ({
        barcode: rslt.barcode.toString(),
        ognoo: format(new Date(rslt.ognoo), 'yyyy-MM-dd'),
        note: rslt.note,
      }));

    if (this.ilgemjlst.length > 0) this.btnLoading1 = false;
  }
}
