import {  Component, OnInit, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { IlgeemjService } from '../services/ilgeemj.service';
import { TavtransService } from '../services/tavtrans.service';
import { TaviurService } from '../services/taviur.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
@Component({
  selector: 'app-taviur',
  templateUrl: './taviur.component.html',
  styleUrls: ['./taviur.component.css']
})
export class TaviurComponent implements OnInit {


  tavtrnslst: any;
  tavlst: any;
  VsbleBnk: boolean = false;

  startValue: Date | null = null;
  endValue: Date | null = null;
  btnlding = false;
  btnlding1 = false;

  ilgemjDta: any = {
    barcode: null,
    ognoo: null,
    utas: null,
    price: null,
    hemjee: null,
    userid: 1
  }

  slctbarcde = '';
  slctilgemj: any;


  ilgemjtav: any = {
    ilg_id: null,
    tav_id: null,
    ognoo: new Date(),
    note: null,
    userid: 1
  }


  newtav: any = {
    tavnme: null,
    tailbar: null
  }

  uptav: any = {
    tav_id: null,
    oldtavnme: null,
    tavnme: null,
    tailbar: null
  }





  byutasilgemj: any;


  constructor( private modal: NzModalService, private notification: NzNotificationService, private tavTrnSrvce: TavtransService, private tavSrvce: TaviurService) { }

  ngOnInit(): void {

    this.tavTrnSrvce.getTavtrans().subscribe((lsttav: any) => (this.tavtrnslst = lsttav));
    this.tavSrvce.getTavtoo().subscribe((tav: any) => (this.tavlst = tav));
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() <= this.startValue.getTime();
  };

  handleStartOpenChange(open: boolean): void {
    if (this.startValue && this.endValue) {
      // this.sales = this.sales.filter(
      //   (item) =>
      //     new Date(item.invdate) >= this.startValue &&
      //     new Date(item.invdate) <= this.endValue
      // );
    }
  }

  handleEndOpenChange(open: boolean): void {
    // if (this.startValue && this.endValue) {
    //   this.sales = this.sales.filter(
    //     (item) =>
    //       new Date(item.invdate) >= this.startValue &&
    //       new Date(item.invdate) <= this.endValue
    //   );
    // }
  }

  slctDternge(): void {
    // if (this.startValue && this.endValue) {
    //   this.borlultSrvce.getSales().subscribe((lst) => {
    //     this.sales = lst.filter(
    //       (item) =>
    //         new Date(item.invdate) >= this.startValue &&
    //         new Date(item.invdate) <= this.endValue
    //     );
    //   });
    // }
  }

  rstDteRnge(): void {
    this.startValue = null;
    this.endValue = null;
    // this.borlultSrvce.getSales().subscribe((lst) => (this.sales = lst));
  }


  handleCancel(): void {


    this.VsbleBnk = false;
  }


  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  selctfunc(event: any) {
    event.target.select();
  }

  newIlgemj(){
    this.VsbleBnk = true;
  }




  mdldestroy(modelRef: NzModalRef): void {
    this.uptav = {
      tav_id: null,
      oldtavnme: null,
      tavnme: null,
      tailbar: null
    }
    modelRef.destroy();

  }

  newTavdestroy(modelRef: NzModalRef): void {
    this.newtav = {
      tavnme: null,
      tailbar: null
    }
    modelRef.destroy();

  }


  huvIlgemj(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, item: any): void {
    if(item) {
      this.uptav.tav_id = item.tav_id;
      this.uptav.oldtavnme = item.tavnme;
      this.uptav.tailbar = item.tailbar;
      this.modal.create({
        nzTitle: tplTitle,
        nzContent: tplContent,
        nzFooter: tplFooter,
        nzMaskClosable: false,
        nzClosable: false,

        nzOnOk: () => console.log('Click ok')
      });

    } else {

      this.crteNtcn('warning', 'Тавиур сонгоогүй байна.', '');

    }

  }


  newTaviur(tplTitle1: TemplateRef<{}>, tplContent1: TemplateRef<{}>, tplFooter1: TemplateRef<{}>): void {
    if(true) {
      // this.uptav.tav_id = item.tav_id;
      // this.uptav.oldtavnme = item.tavnme;
      // this.uptav.tailbar = item.tailbar;
      this.modal.create({
        nzTitle: tplTitle1,
        nzContent: tplContent1,
        nzFooter: tplFooter1,
        nzMaskClosable: false,
        nzClosable: false,

        nzOnOk: () => console.log('Click ok')
      });

    } else {

      this.crteNtcn('warning', 'Тавиур сонгоогүй байна.', '');

    }

  }



  hadhaah(modelRef: NzModalRef): void {

    if(this.uptav.tavnme ){
    this.btnlding = true;
    this.tavSrvce.Uptaviur(this.uptav).subscribe((rslt:any)=> {
      this.crteNtcn('success', 'Амжилттай хадгалсан.', '');
      console.log(rslt);
      this.btnlding = false;
      this.tavSrvce.getTavtoo().subscribe((tav: any) => (this.tavlst = tav));
      this.uptav = {
        tav_id: null,
        oldtavnme: null,
        tavnme: null,
        tailbar: null
      }

    });

    this.btnlding = false;

    modelRef.destroy();
    } else {

      this.btnlding = false;
      this.crteNtcn('warning', 'Бүрэн бөглөх ёстой.', '');

    }

  }


  newTavhad(modelRef: NzModalRef): void {

    if(this.newtav.tavnme ){
    this.btnlding = true;
    this.tavSrvce.newTav(this.newtav).subscribe((rslt:any)=> {
      this.crteNtcn('success', 'Амжилттай хадгалсан.', '');

      this.btnlding = false;
      this.tavSrvce.getTavtoo().subscribe((tav: any) => (this.tavlst = tav));
      this.newtav = {
        tavnme: null,
        tailbar: null
      }

    });

    this.btnlding = false;

    modelRef.destroy();
    } else {

      this.btnlding = false;
      this.crteNtcn('warning', 'Бүрэн бөглөх ёстой.', '');

    }

  }





  crteNtcn(type: string, title: string, message: string): void {
    this.notification.create(type,
      title,
      message
    );
  }




  tavbyutas(): void {
    if(this.byutasilgemj){
      this.byutasilgemj = null;
    } else {
      this.byutasilgemj = this.tavtrnslst.filter((rslt: any) => {Number(rslt.utas) === Number(this.slctilgemj.utas)});
      console.log(this.byutasilgemj);
    }


  }

  remTav(item: any): void{
   if( this.tavtrnslst.filter((itms: any)=>itms.tav_id ==item.tav_id).length > 0 ? true : false){
    this.crteNtcn('warning', 'Устгах боломжгүй', '');
   } else {
     this.tavSrvce.deltaviur(item.tav_id).subscribe((rslt:any)=>{
      this.crteNtcn('success', 'Амжилттай устгасан.', '');
      this.tavSrvce.getTavtoo().subscribe((tav: any) => (this.tavlst = tav))
     });
    ;
   }
  }







}
