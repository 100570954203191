<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="sidebar-logo">
      <a href="http://localhost:4200/" target="_blank">
        <img src="assets/logo0.png" alt="logo" class="logo" />
        <h1>АМАР Карго</h1>
      </a>
    </div>

    <nz-divider style="background-color: #f0f2f5; margin: 0px"></nz-divider>

    <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Эхлэл' : ''"
      >
        <i nz-icon nzType="dashboard"></i>
        <span><a routerLink="/welcome">Эхлэл</a></span>
      </li>

      <li nz-submenu nzTitle="Ачаа бүртгэл" nzIcon="barcode">
        <ul>
          <li nz-menu-item>
            <span><a routerLink="/ereen">Эрээн ачаа бүртгэх</a></span>
          </li>
          <li nz-menu-item>
            <span><a routerLink="/ereendirsen">Эрээн ирсэн ачаа</a></span>
          </li>
          <li nz-menu-item>
            <span><a routerLink="/taviur">Тавиур</a></span>
          </li>
          <li nz-menu-item>
            <span><a routerLink="/achaaburt">Ачаа бүртгэх</a></span>
          </li>
        </ul>
      </li>
      <!-- <li nz-submenu nzTitle="Ачаа хувиарлалт" nzIcon="appstore-add">
        <ul>
          <li nz-menu-item><span><a routerLink="/achaahuv">Ачаа хувиарлах</a></span></li>
          <li nz-menu-item><span><a routerLink="/huviargui">Хувиарлаагүй ачаа</a></span></li>
          <li nz-menu-item><span><a routerLink="/taviur">Tавиур</a></span></li>


        </ul>
      </li> -->
      <li nz-submenu nzTitle="Ачаа олголт" nzIcon="export">
        <ul>
          <li nz-menu-item>
            <span><a routerLink="/olgolt">Ачаа олгох</a></span>
          </li>
          <li nz-menu-item>
            <span><a routerLink="/olgoson">Олгосон ачаа</a></span>
          </li>
          <li nz-menu-item>
            <span><a routerLink="/barimtud">Баримтууд</a></span>
          </li>
          <li nz-menu-item>
            <span><a routerLink="/avlaga">Авлага</a></span>
          </li>
          <!-- <li nz-menu-item><span><a routerLink="/aguululd">Агуулах үлдэгдэл</a></span></li>
          <li nz-menu-item><span><a routerLink="/baradtl">Тооллого</a></span></li> -->
        </ul>
      </li>
      <!-- <li nz-submenu nzTitle="Салбарын бүртгэл" nzIcon="apartment">
        <ul>
          <li nz-menu-item><span><a routerLink="/salbuld">Барааны үлэгдэл</a></span></li>

          <li nz-menu-item><span><a routerLink="/salbavl">Авлагын бүртгэл</a></span></li>

        </ul>
      </li> -->
      <!-- <li nz-submenu nzTitle="Захиалга" nzIcon="edit" nzMatchRouter>
        <ul>
          <li nz-menu-item><span><a routerLink="/zahialga">Захиалга бүртгэх</a></span></li>
          <li nz-menu-item><span><a routerLink="/zahlist">Захиалга жагсаалт</a></span></li>

        </ul>
      </li> -->
      <!-- <li nz-submenu nzTitle="Борлуулалт" nzIcon="shopping-cart" nzMatchRouter> -->
      <!-- <ul> -->
      <!-- <li nz-menu-item><span><a routerLink="/addsale">Борлуулалт бүртгэх</a></span></li>
          <li nz-menu-item><span><a routerLink="/sales">Борлуулалтын баримтууд</a></span></li> -->
      <!-- <li nz-menu-item><span><a routerLink="/negtgel">Ачуулаагүй бараа нэгтгэл</a></span></li> -->

      <!-- </ul> -->
      <!-- </li> -->
      <!-- <li nz-submenu nzTitle="Худалдан авалт" nzIcon="form" nzMatchRouter>
        <ul>
          <li nz-menu-item><span><a routerLink="/purchase">Худалдан авалт бүртгэх</a></span></li>
          <li nz-menu-item><span><a routerLink="/purlst">Худалдан авалт баримт</a></span></li>
        </ul>
      </li>

      <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Харилцагч' : ''">
        <i nz-icon nzType="smile"></i>
        <span><a routerLink="/customer">Харилцагчид</a></span>
      </li> -->
      <!-- <li nz-submenu nzTitle="Харилцагчид" nzIcon="smile" nzMatchRouter>
        <span> <a routerLink="/customer">Харилцагчид</a></span>
      </li> -->

      <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Нийлүүлэгч' : ''">
        <i nz-icon nzType="team"></i>
        <span><a routerLink="/supplier">Нийлүүлэгчид</a></span>
      </li> -->
      <!-- <li nz-submenu nzTitle="Нийлүүлэгчид" nzIcon="team" nzMatchRouter>
        <ul>
          <li nz-menu-item><span><a routerLink="/supplier">Нийлүүлэгчид</a></span></li>
          <li nz-menu-item>Option 6</li>
        </ul>
      </li> -->
      <li nz-submenu nzTitle="Хүргэлтийн ачаа" nzIcon="book" nzMatchRouter>
        <ul>
          <li nz-menu-item>
            <span><a routerLink="/hursongoh">Хүргэлт сонгосон ачаа</a></span>
          </li>
          <li nz-menu-item>
            <span><a routerLink="/hurgelt">Хүргэлтээр гарсан ачаа</a></span>
          </li>
          <li nz-menu-item>
            <span><a routerLink="/hurgelt">Хүргэсэн ачаанууд</a></span>
          </li>
        </ul>
      </li>

      <li
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Систем хэрэглэгч' : ''"
      >
        <i nz-icon nzType="user"></i>
        <span><a routerLink="/user">Систем хэрэглэгч</a></span>
      </li>
      <li
        nz-menu-item
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? 'Систем тохиргоо' : ''"
      >
        <i nz-icon nzType="setting"></i>
        <span><a routerLink="/setting">Систем тохиргоо</a></span>
      </li>
    </ul>

    <!-- <div class="sidebar1-logo">
      <a href="http://43.231.114.145:9040:4200/" target="_blank">
        <img src="assets/logo.png" alt="logo">
        <h1 *ngIf="!isCollapsed">АМУР Стор 1.0</h1>
      </a>
    </div> -->
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
          <i
            class="trigger"
            nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
          ></i>
        </span>

        <span class="right-header">
          <nz-avatar
            nzIcon="user"
            nzSrc="https://mglapi.amar.ac:35/uploads/{{ loggedUsrZur() }}"
          ></nz-avatar>
          <a> {{ loggedUsrNer() }}</a>
          <a
            *ngIf="loggedUsrNer()"
            style="margin-left: 15px"
            (click)="logout()"
          >
            Гарах
            <i nz-icon nzType="logout" nzTheme="outline"></i>
          </a>
        </span>
      </div>
    </nz-header>

    <nz-content>
      <router-outlet></router-outlet>
    </nz-content>
    <nz-footer>©2024, Амaрапп ХХК, www.amar.ac, 88050079</nz-footer>
  </nz-layout>
</nz-layout>
