import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ZuragService {
  constructor(private http: HttpClient) {}
  zurUpld(frm: any): Observable<any> {
    const headopt = {
      headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
    };

    return this.http.post<any>('https://mglapi.amar.ac:35/zurag', frm);
  }
}
