<span (window:keydown)="onKeydownHandler($event)">
  <div class="site-page-header-ghost-wrapper">
    <nz-page-header
      [nzGhost]="false"
      style="
        background-color: #fae2a0;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      "
    >
      <nz-page-header-title>Бүртгэсэн ачаанууд</nz-page-header-title>
      <!-- <nz-page-header-subtitle>This is a subtitle</nz-page-header-subtitle> -->
      <nz-page-header-extra
        style="display: flex; /* justify-content: flex-end; */"
      >
        <nz-page-header-title style="color: dimgrey"
          >Ач/тоо:
          {{ findbycode()?.length | number : "1.0-2" }}</nz-page-header-title
        >
        <nz-page-header-title style="color: dimgrey"
          >Ач/дүн: {{ sumCheck() | number : "1.0-2" }}</nz-page-header-title
        >
        <button
          nz-button
          nzType="primary"
          nzShape="round"
          (click)="addIlgemj(tplTitle, tplContent, tplFooter)"
        >
          <i nz-icon [nzType]="'plus'"></i>Ачаа
        </button>
      </nz-page-header-extra>
    </nz-page-header>
  </div>

  <div class="inner-content">
    <div nz-row [nzGutter]="{ xs: 4, sm: 6, md: 8, lg: 10 }">
      <div nz-col class="gutter-row" [nzSpan]="6">
        <div class="inner-box">
          <form nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-form-control>
                <nz-input-group nzSearch [nzSuffix]="inputClear1Tpl">
                  <input
                    type="text"
                    nz-input
                    placeholder="Утасны дугаараар хайх"
                    [(ngModel)]="hailtutas"
                    name="utas"
                    (keyup)="focBarcode()"
                  />
                </nz-input-group>
                <ng-template #inputClear1Tpl>
                  @if (hailtutas) {
                  <span
                    nz-icon
                    class="ant-input-clear-icon"
                    nzTheme="fill"
                    nzType="close-circle"
                    (click)="hailtutas = null"
                  ></span>
                  }
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>
        </div>
      </div>
      <div nz-col class="gutter-row" [nzSpan]="6">
        <div class="inner-box">
          <form nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-form-control>
                <nz-input-group [nzSuffix]="inputClearTpl">
                  <input
                    type="text"
                    nz-input
                    placeholder="Бар кодоор хайх"
                    [(ngModel)]="barcode"
                    name="barcode"
                    (keyup)="focUtas()"
                  />
                </nz-input-group>
                <ng-template #inputClearTpl>
                  @if (barcode) {
                  <span
                    nz-icon
                    class="ant-input-clear-icon"
                    nzTheme="fill"
                    nzType="close-circle"
                    (click)="barcode = null"
                  ></span>
                  }
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </form>
        </div>
      </div>

      <div nz-col class="gutter-row" [nzSpan]="5">
        <div class="inner-box">
          <form nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-date-picker
                [nzSize]="'default'"
                [(ngModel)]="ognoo"
                (ngModelChange)="findbycode()"
                name="ognoo"
                nzPlaceHolder="Огноо сонгох"
              ></nz-date-picker>
            </nz-form-item>
          </form>
        </div>
      </div>
      <div nz-col class="gutter-row" [nzSpan]="7">
        <div style="display: flex; justify-content: flex-end">
          <button
            nz-button
            nzType="primary"
            nzShape="round"
            (click)="exportAsXLSX()"
          >
            <span nz-icon nzType="file-excel" nzTheme="outline"></span>Excel
          </button>
        </div>
      </div>
    </div>
    <nz-table
      #ilgemjlst1
      [nzData]="findbycode()"
      nzSize="small"
      nzTableLayout="auto"
      [nzFrontPagination]="true"
      [nzPageSize]="20"
    >
      <thead>
        <tr>
          <th>#</th>
          <th nzWidth="10px">Тавиур</th>
          <th nzWidth="8%">Утас</th>
          <th nzWidth="15%">Бар код</th>
          <th nzWidth="8%">Огноо#</th>
          <th nzWidth="6%">Тоо</th>
          <th>Үнэ</th>
          <th>Тайлбар</th>

          <!-- <th>Төлсөн</th> -->
          <!-- <th>Үлдэгдэл</th>
        <th>Ачуулсан</th> -->
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of ilgemjlst1.data; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ item.taviur }}</td>
          <td>{{ item.utas }}</td>
          <td>{{ item.barcode }}</td>
          <td>{{ item.ognoo | date : "yyyy/MM/dd" }}</td>
          <td>{{ item.hemjee }}</td>
          <td>{{ item.price | number : "1.0-0" }}</td>
          <td>{{ item.note }}</td>

          <td>
            <nz-button-group nzSize="small">
              <button
                nz-button
                nzType="primary"
                class="editbtn"
                (click)="
                  showUpdate(item, tplTitleup, tplContentup, tplFooterup)
                "
              >
                <i
                  nz-icon
                  nzType="form"
                  nz-tooltip
                  nzTooltipTitle="Засварлах"
                ></i>
              </button>
              <button
                nz-button
                nzType="primary"
                class="editbtn"
                (click)="showDel(item)"
              >
                <i
                  nz-icon
                  nzType="delete"
                  nz-tooltip
                  nzTooltipTitle="Устгах"
                ></i>
              </button>
            </nz-button-group>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <div class="spin" *ngIf="!ilgemjlst">
      <nz-spin nzSimple></nz-spin>
    </div>
  </div>

  <nz-modal
    [(nzVisible)]="VsbleBnk"
    [nzTitle]="modalTitle"
    [nzContent]="modalContent"
    [nzFooter]="modalFooter"
    (nzOnCancel)="handleCancel()"
  >
    <ng-template #modalTitle>Мөнгөн гүйлгээ бүртгэх</ng-template>

    <ng-template #modalContent>
      <form nz-form>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24">#</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <div nz-row [nzGutter]="8">
              <div nz-col [nzSpan]="12">
                <input nz-input name="dugaar" [disabled]="true" />
              </div>
              <div nz-col [nzSpan]="12">
                <nz-date-picker
                  nzPlaceHolder="Огноо"
                  name="dte"
                ></nz-date-picker>
              </div>
            </div>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24">Гүйлгээ төрөл</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <!-- <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Гүйлгээний төрөл сонгох..." [(ngModel)]="bnkDta.bnkaccntid" name="bnktype">
                <nz-option *ngFor="let acc of bnkacclst" nzLabel="{{acc.bnkname}} {{acc.bnknum}}" [nzValue]="acc.bnkid"></nz-option>

              </nz-select> -->
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24">Дүн</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <div nz-row [nzGutter]="8">
              <div nz-col [nzSpan]="12">
                <input
                  nz-input
                  name="dun"
                  (keypress)="numberOnly($event)"
                  (focus)="selctfunc($event)"
                />
              </div>

              <!-- <div nz-col [nzSpan]="12" style="margin-left: -4px; margin-right: 4px; margin-top: 5px; font-style: italic;">
                  {{dunChnge() | number: '1.0-0'}}
                </div> -->
            </div>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="6" [nzXs]="24">Тайлбар</nz-form-label>
          <nz-form-control [nzSm]="14" [nzXs]="24">
            <textarea
              nz-input
              name="memo"
              rows="2"
              placeholder="Гүйлгээний тайлбар бичих..."
            ></textarea>
          </nz-form-control>
        </nz-form-item>
      </form>
    </ng-template>

    <ng-template #modalFooter>
      <button nz-button nzType="default">Болих</button>
      <button nz-button nzType="primary">Хадгалах</button>
    </ng-template>
  </nz-modal>

  <ng-template #tplTitle>
    <span>Ачаа бүртгэх<i nz-icon nzType="plus-circle"></i></span>
  </ng-template>
  <ng-template #tplContent let-params>
    <div nz-row [nzGutter]="[8, 0]">
      <div nz-col nzSpan="12">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Бар код</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                id="barcde"
                placeholder="Бар код..."
                [(ngModel)]="ilgemjDta.barcode"
                name="barcode"
                (focus)="selctfunc($event)"
                autocomplete="off"
                maxlength="25"
                autofocus
                (keyup.enter)="utas1(utas)"
                (keypress)="barCode($event)"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="12" style="margin-bottom: 0px; margin-top: 0px">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Утас</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                placeholder="Утас..."
                [(ngModel)]="ilgemjDta.utas"
                name="utas"
                (keyup.enter)="taviur1(taviur)"
                maxlength="8"
                (keypress)="numberOnly($event)"
                #utas
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="6">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Хэм.нэгж</nz-form-label>
            <nz-form-control>
              <nz-select
                nzAllowClear
                nzPlaceHolder="Сонгох..."
                [(ngModel)]="ilgemjDta.hemneg"
              >
                <nz-option
                  *ngFor="let hem of hemnegj"
                  [nzValue]="hem.id"
                  [nzLabel]="hem.nme"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="6">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Тавиур</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                placeholder="тавиур..."
                [(ngModel)]="ilgemjDta.taviur"
                name="taviur"
                (keyup.enter)="hemjee1(hemjee)"
                maxlength="5"
                (keypress)="numberOnly($event)"
                #taviur
              />

              <!-- <nz-select
                #taviur
                nzAllowClear
                placeholder="тавиур..."
                nzShowSearch
                [nzShowArrow]="false"
                [(ngModel)]="ilgemjDta.taviur"
                name="taviur"
                (keyup.enter)="hemjee1(hemjee)"
                maxlength="5"
                (keypress)="numberOnly($event)"
              >
                <nz-option
                  *ngFor="let tav of taviurlst"
                  [nzValue]="tav.tav_id"
                  [nzLabel]="tav.tavnme"
                ></nz-option>
              </nz-select> -->
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-col nzSpan="5">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Тоо/хэм</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                placeholder="Хэмжээ..."
                [(ngModel)]="ilgemjDta.hemjee"
                name="hemjee"
                (keyup.enter)="une1(une)"
                #hemjee
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="7">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Карго үнэ</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                placeholder="Үнэ..."
                [(ngModel)]="ilgemjDta.price"
                name="price"
                (keyup.enter)="note1(note)"
                (keypress)="numberOnly($event)"
                #une
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="7">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Огноо</nz-form-label>
            <nz-form-control>
              <nz-date-picker [(ngModel)]="ilgemjDta.ognoo"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="17">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Тайлбар</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                placeholder="Энэ хэсэгт тайлбар бичнэ..."
                [(ngModel)]="ilgemjDta.note"
                (keyup.enter)="hadgalah()"
                name="tailbar"
                #note
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #tplFooter let-ref="modalRef">
    <div style="float: left">
      <button nz-button (click)="ref.destroy()">
        <i nz-icon nzType="poweroff"></i>Хаах
      </button>
    </div>
    <button
      nz-button
      nzType="primary"
      (click)="hadgalah()"
      [nzLoading]="btnlding1"
    >
      <i nz-icon nzType="save"></i>Хадгалах
    </button>
    <button
      nz-button
      nzType="primary"
      (click)="hadhaah(ref)"
      [nzLoading]="btnlding"
    >
      <i nz-icon nzType="save"></i>Хадгалах<i nz-icon nzType="arrow-right"></i
      >Хаах
    </button>
  </ng-template>

  <nz-modal
    [(nzVisible)]="VsbleDel"
    [nzContent]="modalcondel"
    [nzFooter]="modalFootdel"
    (nzOnCancel)="cancel()"
  >
    <ng-template #modalcondel>
      <span style="padding-left: 210px"
        ><i
          nz-icon
          nzType="warning"
          nzTheme="twotone"
          [nzTwotoneColor]="'red'"
          style="margin-right: 6px; font-size: 36px; margin-bottom: 10px"
        ></i
      ></span>

      <p>
        <strong
          ><span nz-typography nzType="warning" style="font-size: 16px"
            >#{{ selIlgeemj?.barcode }}</span
          ></strong
        >
        дугаартай ачааг устгахад итгэлтэй байна уу.
      </p>
    </ng-template>

    <ng-template #modalFootdel>
      <button nz-button nzType="default" (click)="cancel()">Болих</button>
      <button
        nz-button
        nzType="primary"
        (click)="confirm()"
        [nzLoading]="loadingDel"
      >
        Устгах
      </button>
    </ng-template>
  </nz-modal>

  <ng-template #tplTitleup>
    <span>Ачаа засварлах<i nz-icon nzType="check-circle"></i></span>
  </ng-template>
  <ng-template #tplContentup let-params>
    <div nz-row [nzGutter]="[8, 0]">
      <div nz-col nzSpan="12">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Бар код</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                id="barcde"
                placeholder="Бар код..."
                [(ngModel)]="ilgemjUpdate.barcode"
                name="barcode"
                (focus)="selctfunc($event)"
                autocomplete="off"
                maxlength="25"
                autofocus
                (keyup.enter)="utas1(utas)"
                disabled
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="12" style="margin-bottom: 0px; margin-top: 0px">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Утас</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                placeholder="Утас..."
                [(ngModel)]="ilgemjUpdate.utas"
                name="utas"
                (keyup.enter)="hemjee2(hemjee)"
                maxlength="8"
                (keypress)="numberOnly($event)"
                #utas
                (focus)="selctfunc($event)"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="12">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Хэм.нэгж</nz-form-label>
            <nz-form-control>
              <nz-select
                nzAllowClear
                nzPlaceHolder="Сонгох..."
                [(ngModel)]="ilgemjUpdate.hemneg"
              >
                <nz-option
                  *ngFor="let hem of hemnegj"
                  [nzValue]="hem.id"
                  [nzLabel]="hem.nme"
                ></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div nz-col nzSpan="6">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Тоо/хэм</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                placeholder="Хэмжээ..."
                [(ngModel)]="ilgemjUpdate.hemjee"
                name="hemjee"
                (keyup.enter)="une2(une)"
                #hemjee
                (focus)="selctfunc($event)"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="6">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Карго үнэ</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                placeholder="Үнэ..."
                [(ngModel)]="ilgemjUpdate.price"
                name="price"
                (keyup.enter)="note2(note)"
                (keypress)="numberOnly($event)"
                #une
                (focus)="selctfunc($event)"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="7">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Огноо</nz-form-label>
            <nz-form-control>
              <nz-date-picker [(ngModel)]="ilgemjUpdate.ognoo"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="17">
        <div nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Тайлбар</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                placeholder="Энэ хэсэгт тайлбар бичнэ..."
                [(ngModel)]="ilgemjUpdate.note"
                (keyup.enter)="hadgalah()"
                name="tailbar"
                #note
                (focus)="selctfunc($event)"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #tplFooterup let-ref="modalRef">
    <div style="float: left">
      <button nz-button (click)="ref.destroy()">
        <i nz-icon nzType="poweroff"></i>Хаах
      </button>
    </div>

    <button
      nz-button
      nzType="primary"
      (click)="hadhaah2(ref)"
      [nzLoading]="btnlding"
    >
      <i nz-icon nzType="save"></i>Хадгалах<i nz-icon nzType="arrow-right"></i
      >Хаах
    </button>
  </ng-template>
</span>
