import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { IlgeemjService } from '../services/ilgeemj.service';
import { TavtransService } from '../services/tavtrans.service';
import { TaviurService } from '../services/taviur.service';
import { BanktransService } from '../services/banktrans.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzYearPickerComponent } from 'ng-zorro-antd/date-picker';
import { Router } from '@angular/router';
import { formatDistance, subDays, format } from 'date-fns';

@Component({
  selector: 'app-achaolgolt',
  templateUrl: './achaolgolt.component.html',
  styleUrls: ['./achaolgolt.component.css'],
})
export class AchaolgoltComponent implements OnInit {
  haasan = false;

  ilgemjlst: any;

  tavlst: any;
  VsbleBnk: boolean = false;

  startValue: Date | null = null;
  endValue: Date | null = null;
  btnlding = false;
  btnlding1 = false;

  ilgemjDta: any = {
    barcode: null,
    ognoo: null,
    utas: null,
    price: null,
    hemjee: null,
    userid: 1,
  };
  barcode: any;
  slctbarcde = '';
  slctilgemj: any;

  ilgemjtav: any = {
    ilg_id: null,
    tav_id: null,
    ognoo: new Date(),
    note: null,
    userid: 1,
  };

  byutasilgemj: any;
  hailtuts: any;

  tavtrnslst1: any;

  olgoltLst: any;
  Olgsonids: any;
  checkedLst: any = [];

  Dtaolgsn: any = {
    olgsonids: null,
    ognoo: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS"),
    utas: null,
    note: null,
    userid: 1,
    olgsondtls: [
      {
        ilg_id: null,
        olgsonids: null,
        ognoo: null,
        hemjee: null,
        price: null,
        tav_id: null,
      },
    ],
  };

  Databnk: any = {
    bnktrnsno: null,
    bnktrnstype: 1,
    bnkaccntid: 1,
    bnktrnsdte: null,
    bnkamnt: null,
    usrid: 1,
    olgsontrns: null,
    bnkmemo: null,
    utas: null,
  };

  dtaCash: any = 0;
  dtaBnk: any = 0;
  Zeel: any = 0;

  allChecked = false;
  displayData: any = [];
  indeterminate = false;
  checkbox = false;
  fixedColumn = false;

  bnkids: any;
  avlgbyutas: any = [];
  lggedusr: any;

  constructor(
    private ilgemjSrvce: IlgeemjService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private notification: NzNotificationService,
    private tavTrnSrvce: TavtransService,
    private tavSrvce: TaviurService,
    private bnkSrvce: BanktransService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.chkLgdUsr();
    this.ilgemjSrvce
      .getIlgemj()
      .subscribe((lst: any) => (this.ilgemjlst = lst));
    this.tavSrvce.getTaviur().subscribe((tav: any) => (this.tavlst = tav));
    this.bnkSrvce.getAvlbyUtas().subscribe((avl: any) => {
      this.avlgbyutas = avl;
    });

    this.ilgemjDta.ognoo = new Date();
    this.ilgemjtav.ognoo = new Date();
    this.olgoltLst = [];
    this.checkedLst = [];

    this.bnkAmnt();
    this.cashAmnt();
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() <= this.startValue.getTime();
  };

  handleStartOpenChange(open: boolean): void {
    if (this.startValue && this.endValue) {
      // this.sales = this.sales.filter(
      //   (item) =>
      //     new Date(item.invdate) >= this.startValue &&
      //     new Date(item.invdate) <= this.endValue
      // );
    }
  }

  handleEndOpenChange(open: boolean): void {
    // if (this.startValue && this.endValue) {
    //   this.sales = this.sales.filter(
    //     (item) =>
    //       new Date(item.invdate) >= this.startValue &&
    //       new Date(item.invdate) <= this.endValue
    //   );
    // }
  }

  slctDternge(): void {
    // if (this.startValue && this.endValue) {
    //   this.borlultSrvce.getSales().subscribe((lst) => {
    //     this.sales = lst.filter(
    //       (item) =>
    //         new Date(item.invdate) >= this.startValue &&
    //         new Date(item.invdate) <= this.endValue
    //     );
    //   });
    // }
  }

  rstDteRnge(): void {
    this.startValue = null;
    this.endValue = null;
    // this.borlultSrvce.getSales().subscribe((lst) => (this.sales = lst));
  }

  // handleCancel(): void {
  //   this.VsbleBnk = false;
  // }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  selctfunc(event: any) {
    event.target.select();
  }

  huvIlgemj(
    tplTitle: TemplateRef<{}>,
    tplContent: TemplateRef<{}>,
    tplFooter: TemplateRef<{}>
  ): void {
    if (this.olgoltLst.length > 0) {
      this.lstIds();
      this.modal.create({
        nzTitle: tplTitle,
        nzContent: tplContent,
        nzFooter: tplFooter,
        nzMaskClosable: false,
        nzClosable: false,
        // nzComponentParams: {
        //   value: 'Template Context',
        // },
        nzOnOk: () => console.log('Click ok'),
      });

      //this.crteNtcn('warning', this.slctbarcde + '  кодтой ачаа байхгүй байна.', '');
      //this.slctbarcde = '';
    } else {
      this.crteNtcn('warning', 'Ачаа байхгүй байна.', '');
    }
  }

  hadhaah(modelRef: NzModalRef): void {
    if (this.Dtaolgsn.olgsonids && this.Dtaolgsn.ognoo && this.Dtaolgsn.utas) {
      this.btnlding = true;
      this.Dtaolgsn.olgsondtls = this.checkedLst.map((item: any) => ({
        ilg_id: item.ilg_id,
        olgsonids: this.Dtaolgsn.olgsonids,
        ognoo: format(this.Dtaolgsn.ognoo, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
        hemjee: item.hemjee,
        price: item.price,
        tav_id: item.tav_id,
      }));
      console.log(this.Dtaolgsn);
      this.tavTrnSrvce.newOlgsontrns(this.Dtaolgsn).subscribe((rslt: any) => {
        console.log(rslt);
        this.ilgemjSrvce
          .getIlgemj()
          .subscribe((lst: any) => (this.ilgemjlst = lst));
        this.btnlding = false;
        this.crteNtcn('success', 'Амжилттай хадгалсан.', '');
        this.Databnk.dun = null;
        this.checkedLst = [];
        this.Dtaolgsn = {
          olgsonids: null,
          ognoo: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS"),
          utas: null,
          note: null,
          userid: 1,
          olgsondtls: [
            {
              ilg_id: null,
              olgsonids: null,
              ognoo: null,
              hemjee: null,
              price: null,
              tav_id: null,
            },
          ],
        };

        modelRef.destroy();
      });
    } else {
      this.btnlding = false;
      this.crteNtcn('warning', 'Бүрэн бөглөх ёстой.', '');
    }
  }

  hadhaah1(): void {
    this.tavTrnSrvce.getolgsonids().subscribe((id: any) => {
      this.Olgsonids = 'ol' + (parseInt(id.olgsonids.slice(2)) + 1);
      this.Dtaolgsn.olgsonids = this.Olgsonids;
      //this.Dtaolgsn.utas = this.checkedLst[0].utas;

      if (
        this.Dtaolgsn.olgsonids &&
        this.Dtaolgsn.ognoo &&
        this.Dtaolgsn.utas &&
        (this.dtaBnk || this.dtaCash || this.Zeel)
      ) {
        this.btnlding = true;
        this.Dtaolgsn.olgsondtls = this.checkedLst.map((item: any) => ({
          ilg_id: item.ilg_id,
          olgsonids: this.Dtaolgsn.olgsonids,
          ognoo: format(this.Dtaolgsn.ognoo, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
          hemjee: item.hemjee,
          price: item.price,
          tav_id: item.tav_id,
        }));

        console.log(this.Dtaolgsn);
        this.tavTrnSrvce.newOlgsontrns(this.Dtaolgsn).subscribe((rslt: any) => {
          this.bnkSrvce.getBnkid().subscribe((id: any) => {
            this.bnkids = 'ba' + (parseInt(id.bnktrnsno.slice(2)) + 1);
          });

          this.Databnk.bnktrnsno = this.bnkids;
          this.Databnk.bnktrnsdte = this.Dtaolgsn.ognoo;
          this.Databnk.bnkamnt = Number(this.dtaBnk) + Number(this.dtaCash);
          this.Databnk.olgsontrns = this.Dtaolgsn.olgsonids;
          this.Databnk.utas = this.Dtaolgsn.utas;

          this.bnkSrvce.newBnktrns(this.Databnk).subscribe((rslt: any) => {
            console.log(rslt);
            this.dtaBnk = 0;
            this.dtaCash = 0;
            this.Zeel = 0;
          });

          console.log(rslt);
          this.ilgemjSrvce
            .getIlgemj()
            .subscribe((lst: any) => (this.ilgemjlst = lst));
          this.btnlding = false;
          this.crteNtcn('success', 'Амжилттай хадгалсан.', '');

          this.checkedLst = [];
          this.Dtaolgsn = {
            olgsonids: null,
            ognoo: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS"),
            utas: null,
            note: null,
            userid: 1,
            olgsondtls: [
              {
                ilg_id: null,
                olgsonids: null,
                ognoo: null,
                hemjee: null,
                price: null,
                tav_id: null,
              },
            ],
          };

          this.Databnk = {
            bnktrnsno: null,
            bnktrnstype: 1,
            bnkaccntid: 1,
            bnktrnsdte: null,
            bnkamnt: null,
            usrid: 1,
            olgsntrnsid: null,
            bnkmemo: null,
            utas: null,
          };
        });
      } else {
        this.btnlding = false;
        this.crteNtcn('warning', 'Бүрэн бөглөх ёстой.', '');
      }
    });
  }

  mdldestroy(modelRef: NzModalRef): void {
    this.Olgsonids = null;
    this.Dtaolgsn = {
      olgsonids: null,
      ognoo: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS"),
      utas: null,
      note: null,
      userid: 1,
      olgsondtls: [
        {
          ilg_id: null,
          olgsonids: null,
          ognoo: null,
          hemjee: null,
          price: null,
          tav_id: null,
        },
      ],
    };

    this.Databnk = {
      bnkids: null,
      olgsonids: null,
      ognoo: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS"),
      utas: null,
      dun: null,
      note: null,
      userid: 1,
    };
    modelRef.destroy();
  }

  crteNtcn(type: string, title: string, message: string): void {
    this.notification.create(type, title, message);
  }

  findbyUtas(): any {
    if (this.barcode)
      return this.ilgemjlst
        ? this.ilgemjlst.filter(
            (item: any) => item.utas.search(new RegExp(this.barcode, 'i')) > -1
          )
        : [];
    if (this.hailtuts) {
      return this.ilgemjlst
        ? this.ilgemjlst.filter(
            (item: any) => item.utas.search(new RegExp(this.hailtuts, 'i')) > -1
          )
        : [];
    }
    if (!this.hailtuts && !this.barcode) return this.ilgemjlst;
  }

  choseAcha(brCde: string) {
    let item = this.findbyUtas().filter(
      (itms: any) => itms.barcode === brCde
    )[0];

    if (this.checkedLst.length > 0) {
      if (this.checkedLst[0].utas === item.utas) {
        this.checkedLst.push(item);
        this.ilgemjlst = this.ilgemjlst.filter((d: any) => d.barcode !== brCde);
      } else {
        this.crteNtcn(
          'warning',
          'Анхаар',
          `<span nz-typography><mark><strong>${item.utas}</strong></mark></span> утас дугаартай бараа сонгох боломжгүй.`
        );
      }
    } else {
      this.checkedLst.push(item);
      this.ilgemjlst = this.ilgemjlst.filter((d: any) => d.barcode !== brCde);
    }
  }

  selectRem(event: any) {
    //this.olgoltLst.indexOf(event);
    event.olgoson = false;
    this.ilgemjlst.push(event);
    this.checkedLst.splice(this.checkedLst.indexOf(event), 1);
    // this.checkCart();
  }

  sumCheck(): number {
    return this.findbyUtas()
      ? this.findbyUtas()
          .map((item: any) => item.price)
          .reduce((a: any, b: any) => a + b, 0)
      : null;
  }

  olsumCheck(): number {
    return this.checkedLst
      .map((item: any) => item.price)
      .reduce((a: any, b: any) => a + b, 0);
  }

  lstIds(): void {
    this.tavTrnSrvce.getolgsonids().subscribe((id: any) => {
      this.Olgsonids = 'ol' + (parseInt(id.olgsonids.slice(2)) + 1);
      this.Dtaolgsn.olgsonids = this.Olgsonids;
      this.Dtaolgsn.utas = this.checkedLst[0].utas;
    });
  }

  testfunc(event: any) {
    event.target.select();
  }

  currentPageDataChange($event: any): void {
    this.displayData = $event;
    if (this.displayData) {
      this.refreshStatus();
    }
  }

  refreshStatus(): void {
    const allChecked = this.displayData.every(
      (value: any) => value.olgoson === true
    );
    const allUnChecked = this.displayData.every((value: any) => !value.olgoson);
    this.allChecked = allChecked;
    this.indeterminate = !allChecked && !allUnChecked;
    //console.log(this.displayData.filter((value:any)=>value.olgoson === true));
  }
  checkAll($event: any): void {
    this.displayData.forEach((data: any) => {
      if (data) {
        data.olgoson = $event;
      }
    });

    this.refreshStatus();
  }

  selAllbtn(): boolean {
    return this.displayData.filter((value: any) => value.olgoson === true)
      .length
      ? true
      : false;
  }

  selNmbr(): number {
    return this.displayData.filter((value: any) => value.olgoson === true)
      .length;
  }

  selAll(): void {
    this.displayData
      .filter((value: any) => value.olgoson === true)
      .forEach((itm: any) => {
        this.checkedLst.push(itm);
        this.ilgemjlst.splice(this.ilgemjlst.indexOf(itm), 1);
      });
  }

  achaaCheck(): void {
    let item = null;
    item = this.olgoltLst.find((itms: any) => itms.barcode == this.slctbarcde);
    if (item)
      this.checkedLst.push(item),
        this.olgoltLst.splice(this.olgoltLst.indexOf(item), 1),
        (this.slctbarcde = ''),
        (this.dtaCash = this.barsumCheck());
    if (!item)
      this.crteNtcn('warning', 'Сонгогдоогүй бараа байна.', ''),
        (this.slctbarcde = '');

    if (this.utasLst().length == 1) this.Dtaolgsn.utas = this.utasLst()[0].utas;
    if (this.utasLst().length > 1) this.Dtaolgsn.utas = null;

    console.log(this.utasLst());
  }

  selectRem1(event: any) {
    //this.olgoltLst.indexOf(event);
    event.olgoson = false;
    this.ilgemjlst.push(event);
    this.checkedLst.splice(this.checkedLst.indexOf(event), 1);
    // this.checkCart();
  }

  barsumCheck(): number {
    return this.checkedLst
      ?.map((item: any) => item?.price)
      ?.reduce((a: any, b: any) => a + b, 0);
  }

  utasLst(): any[] {
    //this.checkedLst.map((item: any) => item.utas);
    //return this.checkedLst.map((item: any) => item.utas).filter((value: any, index: any, self: any) => self.indexOf(value) === index);

    return this.checkedLst
      ?.map((item: any) => item?.utas)
      ?.filter(
        (value: any, index: any, self: any) => self.indexOf(value) === index
      )
      .map((utas: any) => ({ utas }))
      .map((itm: any) => ({
        ...this.avlgbyutas.find((item: any) => item.utas == itm.utas && item),
        ...itm,
      }));
  }

  bnkAmnt(): void {
    if (this.dtaCash == 0) this.dtaBnk = this.barsumCheck() - this.Zeel;

    this.dtaBnk = this.barsumCheck() - this.dtaCash - this.Zeel;
    // if((this.dtaCash + this.dtaBnk)<this.barsumCheck()){
    //   this.Zeel = this.barsumCheck() - (this.dtaCash + this.Zeel)
    // } else if ((this.dtaCash + this.dtaBnk) === this.barsumCheck()){
    //   this.Zeel = 0

    // }
  }
  cashAmnt(): void {
    if (this.dtaBnk == 0) this.dtaCash = this.barsumCheck() - this.Zeel;

    this.dtaCash = this.barsumCheck() - this.dtaBnk - this.Zeel;

    // if((this.dtaCash + this.dtaBnk)<this.barsumCheck()){
    //   this.Zeel = this.barsumCheck() - (this.dtaCash + this.Zeel)
    // } else if ((this.dtaCash + this.dtaBnk) === this.barsumCheck()){
    //   this.Zeel = 0

    // }
  }

  zeelAmnt(): void {
    this.dtaBnk = 0;
    this.dtaCash = 0;
  }

  chkLgdUsr(): void {
    if (this.getUser()) {
      this.lggedusr = this.getUser();
    } else {
      this.router.navigate(['/nevtreh']);
    }
  }

  getUser(): any {
    const item = window.localStorage.getItem('amar');
    return item ? JSON.parse(item) : null;
  }

  choseAcha1(barcode1: any) {
    let item = this.findbyUtas().filter(
      (itms: any) => itms.barcode === this.ilgemjDta.barcode
    )[0];

    if (this.checkedLst.length > 0) {
      if (this.checkedLst[0].utas === item.utas) {
        this.checkedLst.push(item);
        this.ilgemjlst = this.ilgemjlst.filter(
          (d: any) => d.barcode !== this.ilgemjDta.barcode
        );
        barcode1.focus();
        barcode1.select();
        this.ilgemjDta.barcode = '';
      } else {
        this.crteNtcn(
          'warning',
          'Анхаар',
          `<span nz-typography><mark><strong>${item.utas}</strong></mark></span> утас дугаартай бараа сонгох боломжгүй.`
        );
        barcode1.focus();
        barcode1.select();
      }
    } else {
      this.checkedLst.push(item);
      this.ilgemjlst = this.ilgemjlst.filter(
        (d: any) => d.barcode !== this.ilgemjDta.barcode
      );
      barcode1.focus();
      barcode1.select();
      this.ilgemjDta.barcode = '';
    }

    // this.findbyUtas().filter(
    //   (item: any) => item.barcode === this.ilgemjDta.barcode
    // ).length > 0
    //   ? this.checkedLst.push(
    //       this.findbyUtas().find(
    //         (itms: any) => itms.barcode === this.ilgemjDta.barcode
    //       )
    //     )
    //   : this.crteNtcn(
    //       'warning',
    //       'Анхаар',
    //       `<span nz-typography><mark><strong>${this.ilgemjDta.barcode}</strong></mark></span> кодтой ачаа байхгүй байна.`
    //     );
    // this.ilgemjlst = this.ilgemjlst.filter(
    //   (d: any) => d.barcode !== this.ilgemjDta.barcode
    // );
  }

  focBarcode() {
    this.hailtuts = '';
    this.findbyUtas();
  }
  focUtas() {
    this.barcode = '';
    this.findbyUtas();
  }
}
