<nz-modal [(nzVisible)]="isVisible" nzClosable="false" nzWidth="320px">
  <div *nzModalContent>
    <div nz-row nzJustify="center" nzAlign="middle" style="margin-bottom: 20px">
      <div nz-col nzSpan="8"></div>
      <div nz-col nzSpan="8">
        <nz-avatar [nzSize]="64" nzIcon="user"></nz-avatar>
      </div>
      <div nz-col nzSpan="8"></div>
    </div>

    <form nz-form class="login-form">
      <!-- <div nz-row>
        <div nz-col [nzSpan]="8">
        </div>
        <div nz-col [nzSpan]="8" nzA>
          <nz-avatar [nzSize]="64" nzIcon="user"></nz-avatar>
        </div>
        <div nz-col [nzSpan]="8">
        </div>
      </div> -->
      <nz-form-item>
        <nz-form-control>
          <nz-input-group nzPrefixIcon="user">
            <input
              type="text"
              nz-input
              placeholder="Нэвтрэх нэр"
              [(ngModel)]="logDta.username"
              name="usrnme"
              (focus)="inpt()"
              (keydown.enter)="handleOk()"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control>
          <nz-input-group nzPrefixIcon="lock">
            <input
              type="password"
              nz-input
              placeholder="Нууц үг"
              [(ngModel)]="logDta.password"
              name="pswrd"
              (focus)="inpt()"
              (keydown.enter)="handleOk()"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <div nz-row class="login-form-margin">
        <div nz-col [nzSpan]="24">
          <label>
            <span *ngIf="buruu" style="color: orangered"
              >Нууц үг, нэвтрэх нэр буруу!
            </span>
          </label>
        </div>
      </div>
    </form>
  </div>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">
      Нууц үг мартсан
    </button>
    <button
      nz-button
      nzType="primary"
      (click)="handleOk()"
      [nzLoading]="isConfirmLoading"
    >
      Нэвтрэх
    </button>
  </div>
</nz-modal>
