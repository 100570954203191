<div class="site-page-header-ghost-wrapper">
  <nz-page-header
    [nzGhost]="false"
    style="
      background-color: #f5aabd;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    "
  >
    <nz-page-header-title>Баримтууд</nz-page-header-title>
    <!-- <nz-page-header-subtitle>This is a subtitle</nz-page-header-subtitle> -->
    <nz-page-header-extra
      style="display: flex; /* justify-content: flex-end; */"
    >
      <button
        nz-button
        nzType="primary"
        nzShape="round"
        routerLink="/olgolt"
        class="round"
      >
        Ачаа олгох<i nz-icon [nzType]="'arrow-right'"></i>
      </button>
    </nz-page-header-extra>
  </nz-page-header>
</div>

<div class="inner-content">
  <div nz-row [nzGutter]="{ xs: 4, sm: 6, md: 8, lg: 10 }">
    <div nz-col class="gutter-row" [nzSpan]="6">
      <div class="inner-box">
        <form nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label>Утас</nz-form-label>
            <nz-form-control>
              <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                <input
                  type="text"
                  nz-input
                  placeholder="Утасны дугаараар хайх"
                />
              </nz-input-group>
              <ng-template #suffixIconButton>
                <button nz-button nzType="primary" (click)="rstDteRnge()">
                  <i nz-icon nzType="search"></i>
                </button>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </form>
      </div>
    </div>

    <div nz-col class="gutter-row" [nzSpan]="2">
      <div class="inner-box">
        <form nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label></nz-form-label>
            <nz-button-group [nzSize]="'default'">
              <button
                nz-button
                nzType="primary"
                nz-tooltip
                nzTooltipTitle="PDF татах"
              >
                <i nz-icon nzType="file-pdf"></i>
              </button>
              <button
                nz-button
                nzType="primary"
                nz-tooltip
                nzTooltipTitle="Excel татах"
              >
                <i nz-icon nzType="file-excel"></i>
              </button>
            </nz-button-group>
          </nz-form-item>
        </form>
      </div>
    </div>
    <div nz-col class="gutter-row" [nzSpan]="2">
      <div class="inner-box">
        <form nz-form nzLayout="vertical">
          <nz-form-item>
            <nz-form-label></nz-form-label>
            <nz-button-group [nzSize]="'default'">
              <button
                nz-button
                nzType="primary"
                nz-tooltip
                nzTooltipTitle="PDF татах"
              >
                <i nz-icon nzType="file-pdf"></i>
              </button>
              <button
                nz-button
                nzType="primary"
                nz-tooltip
                nzTooltipTitle="Excel татах"
              >
                <i nz-icon nzType="file-excel"></i>
              </button>
            </nz-button-group>
          </nz-form-item>
        </form>
      </div>
    </div>
  </div>
  <nz-table
    #ilgemjlst1
    [nzData]="ilgemjlst"
    nzSize="small"
    nzTableLayout="auto"
    [nzFrontPagination]="true"
    [nzPageSize]="20"
  >
    <thead>
      <tr>
        <th nzWidth="4%">#</th>
        <th nzWidth="8%">Баримт#</th>
        <th nzWidth="8%">Огноо#</th>
        <th nzWidth="8%">Утас</th>
        <th nzWidth="6%">Үнэ</th>
        <th nzWidth="6%">Төлсөн</th>
        <th nzWidth="6%">Үлдэгдэл</th>
        <th>Тайлбар</th>
        <th nzWidth="9%">Б/ажилтан</th>
        <!-- <th>Төлсөн</th> -->
        <!-- <th>Үлдэгдэл</th>
        <th>Ачуулсан</th> -->
        <th nzWidth="3%"></th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of ilgemjlst1.data; let i = index">
        <td>{{ i + 1 }}</td>
        <td>{{ item.olgsonids }}</td>
        <td>{{ item.bnktrnsdte | date : "yyyy/MM/dd" }}</td>
        <td>{{ item.utas }}</td>

        <td [nzAlign]="'right'">{{ item.une | number : "1.0-0" }}</td>
        <td [nzAlign]="'right'">
          {{ item.une - item.bnkamnt | number : "1.0-0" }}
        </td>
        <td [nzAlign]="'right'">{{ item.bnkamnt | number : "1.0-0" }}</td>
        <td>{{ item.bnkmemo }}</td>
        <td>{{ item.usrid }}</td>
        <td>
          <nz-button-group nzSize="small">
            <!-- <button nz-button nzType="primary" class="editbtn"><i nz-icon nzType="form" nz-tooltip nzTooltipTitle="Засварлах"></i></button> -->
            <button nz-button nzType="primary" class="editbtn">
              <i
                nz-icon
                nzType="info"
                nz-tooltip
                nzTooltipTitle="Дэлгэрэнгүй"
              ></i>
            </button>
          </nz-button-group>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <div class="spin" *ngIf="!ilgemjlst">
    <nz-spin nzSimple></nz-spin>
  </div>
</div>
