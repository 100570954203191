import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { formatDate } from '@angular/common';
import { IlgeemjService } from '../services/ilgeemj.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import * as moment from 'moment';
import { Router } from '@angular/router';

import { Observable, Subject, retry, takeUntil, of, Subscription } from 'rxjs';
import { mn_MN } from 'ng-zorro-antd/i18n';
import { format } from 'date-fns';

@Component({
  selector: 'app-achaolgson',
  templateUrl: './achaolgson.component.html',
  styleUrls: ['./achaolgson.component.css'],
})
export class AchaolgsonComponent implements OnInit {
  destroy$ = new Subject<void>();
  subscription = new Subscription();
  ilgemjlst: any;
  VsbleBnk: boolean = false;

  startValue: Date | null = null;
  endValue: Date | null = null;
  btnlding = false;
  btnlding1 = false;

  ilgemjDta: any = {
    barcode: null,
    ognoo: null,
    utas: null,
    price: null,
    hemjee: null,
    userid: 1,
  };

  hailtutas: any;
  barcode: any;

  ognoo: any;
  lggedusr: any;
  ilgemjlst1: any;
  constructor(
    private ilgemjSrvce: IlgeemjService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private notification: NzNotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUser();
    const sub = this.ilgemjSrvce
      .getOlgson()
      .pipe(takeUntil(this.destroy$))
      .subscribe((lst) => (this.ilgemjlst = lst));

    this.ilgemjDta.ognoo = new Date();
  }

  ngOnDestroy(): void {
    //this.subscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() <= this.startValue.getTime();
  };

  handleStartOpenChange(open: boolean): void {
    if (this.startValue && this.endValue) {
      // this.sales = this.sales.filter(
      //   (item) =>
      //     new Date(item.invdate) >= this.startValue &&
      //     new Date(item.invdate) <= this.endValue
      // );
    }
  }

  handleEndOpenChange(open: boolean): void {
    // if (this.startValue && this.endValue) {
    //   this.sales = this.sales.filter(
    //     (item) =>
    //       new Date(item.invdate) >= this.startValue &&
    //       new Date(item.invdate) <= this.endValue
    //   );
    // }
  }

  slctDternge(): void {
    // if (this.startValue && this.endValue) {
    //   this.borlultSrvce.getSales().subscribe((lst) => {
    //     this.sales = lst.filter(
    //       (item) =>
    //         new Date(item.invdate) >= this.startValue &&
    //         new Date(item.invdate) <= this.endValue
    //     );
    //   });
    // }
  }

  rstDteRnge(): void {
    this.startValue = null;
    this.endValue = null;
    // this.borlultSrvce.getSales().subscribe((lst) => (this.sales = lst));
  }

  handleCancel(): void {
    this.VsbleBnk = false;
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  selctfunc(event: any) {
    event.target.select();
  }

  newIlgemj() {
    this.VsbleBnk = true;
  }

  hadgalah(): void {
    if (this.ilgemjDta.barcode && this.ilgemjDta.utas && this.ilgemjDta.price) {
      this.btnlding1 = true;
      this.ilgemjDta.hemjee = +this.ilgemjDta.hemjee;
      this.ilgemjDta.price = +this.ilgemjDta.price;
      this.ilgemjSrvce.newIlgemj(this.ilgemjDta).subscribe((rslt) => {
        this.ilgemjSrvce.getIlgemj().subscribe((lst) => (this.ilgemjlst = lst));
        this.btnlding1 = false;
        this.crteNtcn('success', 'Амжилттай хадгалсан.', '');
        this.ilgemjDta = {
          barcode: null,
          ognoo: new Date(),
          utas: null,
          price: null,
          hemjee: null,
          userid: 1,
        };
      });
    } else {
      this.btnlding1 = false;
      this.crteNtcn('warning', 'Бүрэн бөглөх ёстой.', '');
    }
  }

  crteNtcn(type: string, title: string, message: string): void {
    this.notification.create(type, title, message);
  }

  sumCheck(): number {
    return this.findbycode()
      ? this.findbycode()
          .map((item: any) => item.price)
          .reduce((a: any, b: any) => a + b, 0)
      : null;
  }

  // findbycode(): any {
  //   this.ilgemjlst1 = this.ilgemjlst;
  //   if (this.barcode) {
  //     this.ognoo = null;
  //     this.hailtutas = null;
  //     return this.ilgemjlst1
  //       ? this.ilgemjlst1.filter(
  //           (item: any) =>
  //             item.barcode.search(new RegExp(this.barcode, 'i')) > -1
  //         )
  //       : [];
  //   }

  //   if (this.hailtutas) {
  //     this.ognoo = '';
  //     this.barcode = '';
  //     return this.ilgemjlst1
  //       ? this.ilgemjlst1.filter(
  //           (item: any) =>
  //             item.utas.search(new RegExp(this.hailtutas, 'i')) > -1
  //         )
  //       : [];
  //   }

  //   if (this.ognoo) {
  //     this.barcode = '';
  //     this.hailtutas = '';
  //     return this.findbyognoo(this.ognoo);
  //   }
  //   if (!this.hailtutas && !this.barcode && !this.ognoo) return this.ilgemjlst1;
  // }

  findbyognoo(event: any): any[] {
    this.ilgemjlst1 = this.ilgemjlst;
    console.log(
      formatDate(this.ilgemjlst[0].olgson_dte, 'yyyy/MM/dd', 'en-US')
    );
    console.log(formatDate(event, 'yyyy/MM/dd', 'en-US'));
    return this.ilgemjlst1
      ? this.ilgemjlst1.filter(
          (item: any) =>
            formatDate(item.olgson_dte, 'yyyy/MM/dd', 'en-US') ==
            formatDate(event, 'yyyy/MM/dd', 'en-US')
        )
      : [];
  }
  // findbyUtas(): any {
  //   if (this.barcode)
  //     return this.ilgemjlst
  //       ? this.ilgemjlst.filter(
  //           (item: any) => item.utas.search(new RegExp(this.barcode, 'i')) > -1
  //         )
  //       : [];
  //   if (this.hailtuts) {
  //     return this.ilgemjlst
  //       ? this.ilgemjlst.filter(
  //           (item: any) => item.utas.search(new RegExp(this.hailtuts, 'i')) > -1
  //         )
  //       : [];
  //   }
  //   if (!this.hailtuts && !this.barcode) return this.ilgemjlst;
  // }

  hailtfltr(s: any, l: any) {
    return l.filter(function (item: any) {
      return item.ognoo.find(moment(s).format('DD/MM/YYYY')) !== -1;
    });
  }
  findbycode(): any {
    if (this.barcode)
      return this.ilgemjlst
        ? this.ilgemjlst.filter(
            (item: any) =>
              item.barcode.search(new RegExp(this.barcode, 'i')) > -1
          )
        : [];

    if (this.hailtutas)
      return this.ilgemjlst
        ? this.ilgemjlst.filter(
            (item: any) =>
              item.utas.search(new RegExp(this.hailtutas, 'i')) > -1
          )
        : [];
    if (this.ognoo)
      return this.ilgemjlst
        ? this.ilgemjlst.filter(
            (item: any) =>
              moment(item.ognoo).format('DD/MM/YYYY') ==
              moment(this.ognoo).format('DD/MM/YYYY')
          )
        : [];

    if (!this.hailtutas && !this.barcode && !this.ognoo) return this.ilgemjlst;
  }

  onChange(result: Date): void {
    this.findbycode();
    console.log(result);
    console.log(format(result, 'yyyy/MM/dd'));
    return this.ilgemjlst
      ? this.ilgemjlst.filter(
          (item: any) => item.ognoo == format(result, 'yyyy/MM/dd')
        )
      : [];
  }
  chkLgdUsr(): void {
    if (this.getUser()) {
      this.lggedusr = this.getUser();
    } else {
      this.router.navigate(['/nevtreh']);
    }
  }
  focBarcode() {
    this.barcode = '';
    this.findbycode();
  }
  focUtas() {
    this.hailtutas = '';
    this.findbycode();
  }
  getUser(): any {
    const item = window.localStorage.getItem('amar');
    return item ? JSON.parse(item) : null;
  }
}
