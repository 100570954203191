import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TaviurService {
  constructor(private httpsrvce: HttpClient) {}

  newTav(frm: any): Observable<any> {
    const body = JSON.stringify(frm);
    const catoption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpsrvce.post<any>(
      'https://amarcargoapi.amar.ac/api/taviur',
      body,
      catoption
    );
  }

  getTaviur(): Observable<any[]> {
    return this.httpsrvce.get<any[]>('https://amarcargoapi.amar.ac/api/taviur');
  }

  getTavtoo(): Observable<any[]> {
    return this.httpsrvce.get<any[]>('https://amarcargoapi.amar.ac/api/tavtoo');
  }

  Uptaviur(frm: any): Observable<any> {
    const body = JSON.stringify(frm);
    const catoption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpsrvce.put<any>(
      'https://amarcargoapi.amar.ac/api/uptaviur',
      body,
      catoption
    );
  }

  deltaviur(id: number): Observable<any[]> {
    return this.httpsrvce.delete<any[]>(
      'https://amarcargoapi.amar.ac/api/taviur/' + id
    );
  }
}
