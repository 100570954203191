import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class IlgeemjService {
  constructor(private httpsrvce: HttpClient) {}

  newIlgemj(frm: any): Observable<any> {
    const body = JSON.stringify(frm);
    const catoption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    console.log(body);

    return this.httpsrvce.post<any>(
      'https://amarcargoapi.amar.ac/api/ilgeemj',
      body,
      catoption
    );
  }

  getIlgemj(): Observable<any[]> {
    return this.httpsrvce.get<any[]>(
      'https://amarcargoapi.amar.ac/api/ilgeemj'
    );
  }
  getHurIlgemj(): Observable<any[]> {
    return this.httpsrvce.get<any[]>(
      'https://amarcargoapi.amar.ac/api/hurgeltlst'
    );
  }
  getNohuvIlgemj(): Observable<any[]> {
    return this.httpsrvce.get<any[]>(
      'https://amarcargoapi.amar.ac/api/nohuvilgemj'
    );
  }

  getLstHemnegj(): Observable<any> {
    return this.httpsrvce.get<any>('https://amarcargoapi.amar.ac/api/settings');
  }

  upLsthemnegj(frm: any): Observable<any> {
    const body = JSON.stringify(frm);
    const catoption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpsrvce.put<any>(
      'https://amarcargoapi.amar.ac/api/settings',
      body,
      catoption
    );
  }

  getOlgson(): Observable<any> {
    return this.httpsrvce.get<any>('https://amarcargoapi.amar.ac/api/olgson');
  }

  newEreen(frm: any): Observable<any> {
    const body = JSON.stringify(frm);
    const catoption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    console.log(body);

    return this.httpsrvce.post<any>(
      'https://amarcargoapi.amar.ac/api/ereen',
      frm,
      catoption
    );
  }

  getEreen(id: string): Observable<any> {
    return this.httpsrvce.get<any>(
      'https://amarcargoapi.amar.ac/api/ereenget/' + id
    );
  }

  delereen(id: string): Observable<any[]> {
    return this.httpsrvce.delete<any>(
      'https://amarcargoapi.amar.ac/api/ereen/' + id
    );
  }

  getEreendirsen(): Observable<any> {
    return this.httpsrvce.get<any>(
      'https://amarcargoapi.amar.ac/api/ereendirsen/'
    );
  }

  delIlgeemj(id: string): Observable<any[]> {
    return this.httpsrvce.delete<any>(
      'https://amarcargoapi.amar.ac/api/delilgeemj/' + id
    );
  }

  UpIlgeemj(frm: any): Observable<any> {
    const body = JSON.stringify(frm);
    const catoption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.httpsrvce.put<any>(
      'https://amarcargoapi.amar.ac/api/updateilgeemj',
      body,
      catoption
    );
  }

  RetrnIlgeemj(id: any): Observable<any> {
    return this.httpsrvce.put<any>(
      'https://amarcargoapi.amar.ac/api/butsaalt/',
      +id
    );
  }
}
