<nz-layout>
  <nz-content
    ><div class="site-page-header-ghost-wrapper">
      <nz-page-header
        [nzGhost]="false"
        style="background-color: #88d3ff; border-top-left-radius: 10px"
      >
        <nz-page-header-title
          >Хүргэлтэд гарсан ачааны жагсаалт</nz-page-header-title
        >
        <!-- <nz-page-header-subtitle>This is a subtitle</nz-page-header-subtitle> -->
        <nz-page-header-extra
          style="display: flex; /* justify-content: flex-end; */"
        >
          <nz-page-header-title style="color: dimgrey"
            >Ач/тоо:
            {{ findbyUtas().length | number : "1.0-2" }}</nz-page-header-title
          >
          <nz-page-header-title style="color: dimgrey"
            >Ач/дүн: {{ sumCheck() | number : "1.0-2" }}</nz-page-header-title
          >
        </nz-page-header-extra>
      </nz-page-header>
    </div>

    <div class="inner-content">
      <div nz-row [nzGutter]="{ xs: 4, sm: 6, md: 8, lg: 10 }">
        <div nz-col class="gutter-row" [nzSpan]="6">
          <div class="inner-box">
            <form nz-form nzLayout="vertical">
              <nz-form-item>
                <nz-form-label>Бар код</nz-form-label>
                <nz-form-control>
                  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input type="text" nz-input placeholder="Бар кодоор хайх" />
                  </nz-input-group>
                  <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" (click)="rstDteRnge()">
                      <i nz-icon nzType="search"></i>
                    </button>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </form>
          </div>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="5">
          <div class="inner-box">
            <form nz-form nzLayout="vertical">
              <nz-form-item>
                <nz-form-label>Утас</nz-form-label>
                <nz-form-control>
                  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input
                      type="text"
                      nz-input
                      placeholder="Утасны дугаараар хайх"
                      [(ngModel)]="hailtuts"
                      (input)="findbyUtas()"
                      name="hailtuts"
                    />
                  </nz-input-group>
                  <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" (click)="rstDteRnge()">
                      <i nz-icon nzType="search"></i>
                    </button>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </form>
          </div>
        </div>

        <div nz-col class="gutter-row" [nzSpan]="2">
          <div class="inner-box">
            <form nz-form nzLayout="vertical">
              <nz-form-item>
                <nz-form-label></nz-form-label>
                <nz-button-group [nzSize]="'default'">
                  <!-- <button nz-button nzType="primary" nz-tooltip nzTooltipTitle="PDF татах"><i nz-icon nzType="file-pdf"></i></button>
                <button nz-button nzType="primary" nz-tooltip nzTooltipTitle="Excel татах"><i nz-icon nzType="file-excel"></i></button> -->
                </nz-button-group>
              </nz-form-item>
            </form>
          </div>
        </div>
      </div>
      <nz-table
        #tavtrnslst3
        [nzData]="findbyUtas()"
        (nzCurrentPageDataChange)="currentPageDataChange($event)"
        (nzPageIndexChange)="refreshStatus()"
        (nzPageSizeChange)="refreshStatus()"
        nzSize="small"
        nzTableLayout="auto"
        [nzFrontPagination]="true"
        [nzPageSize]="20"
      >
        <thead>
          <tr>
            <th>#</th>

            <th nzWidth="8%">Утас</th>
            <th nzWidth="12%">Код</th>
            <th>Хүр.Сон.Огноо#</th>
            <th nzWidth="8%">Хэмжээ</th>
            <th nzWidth="8%">Үнэ</th>

            <th nzWidth="10%">Тайлбар</th>
            <th nzWidth="15%">Хаяг</th>

            <th>Жолооч</th>
            <!-- <th>Үлдэгдэл</th>
            <th>Ачуулсан</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of tavtrnslst3.data; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ item.utas }}</td>
            <td>{{ item.barcode }}</td>
            <td>{{ item.hursongsondte | date : "yyyy/MM/dd hh:mm" }}</td>
            <td>{{ item.hemjee }}</td>
            <td>{{ item.price }}</td>

            <td>{{ item.note }}</td>
            <td>{{ item.address }}</td>
            <td>{{ item.drivername }}</td>
          </tr>
        </tbody>
      </nz-table>
      <div class="spin" *ngIf="!ilgemjlst">
        <nz-spin nzSimple></nz-spin>
      </div>
    </div>

    <ng-template #tplTitle>
      <span style="color: #1890ff"
        >Баримт хадгалах <i nz-icon nzType="arrow-right"></i>
        <i nz-icon nzType="phone" nzTheme="outline"></i>
        {{ olgoltLst[0].utas }},
        <i nz-icon nzType="appstore" nzTheme="outline"></i>
        {{ olgoltLst.length }} ачаа, ₮{{
          olsumCheck() | number : "1.0-2"
        }}</span
      >
    </ng-template>
    <ng-template #tplContent let-params>
      <div nz-row [nzGutter]="[8, 8]">
        <div nz-col nzSpan="3">
          <div nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-form-control> #{{ Dtaolgsn.olgsonids }} </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-col nzSpan="7">
          <div nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-form-control>
                <nz-date-picker
                  [nzSize]="'default'"
                  style="display: block"
                  [(ngModel)]="Dtaolgsn.ognoo"
                  name="ognoo"
                ></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-col nzSpan="7">
          <div nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  [value]="olsumCheck()"
                  [disabled]="true"
                  name="tolohdun"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-col nzSpan="7">
          <div nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  placeholder="Төлсөн дүн...."
                  (keypress)="numberOnly($event)"
                  (focus)="testfunc($event)"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-col nzSpan="24" style="margin-top: 5px">
          <div nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  placeholder="Тайлбар..."
                  [(ngModel)]="Dtaolgsn.note"
                  name="note"
                  (focus)="testfunc($event)"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #tplFooter let-ref="modalRef">
      <div style="float: left">
        <button nz-button (click)="mdldestroy(ref)">
          <i nz-icon nzType="poweroff"></i>Хаах
        </button>
      </div>

      <button
        nz-button
        nzType="primary"
        (click)="hadhaah(ref)"
        [nzLoading]="btnlding"
      >
        <i nz-icon nzType="save"></i>Хадгалах
      </button>
    </ng-template>
  </nz-content>
</nz-layout>
