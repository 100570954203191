import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AchburtComponent } from './achburt/achburt.component';
import { AchhuvComponent } from './achhuv/achhuv.component';
import { NohuvachaComponent } from './nohuvacha/nohuvacha.component';
import { AchaolgoltComponent } from './achaolgolt/achaolgolt.component';
import { AchaolgsonComponent } from './achaolgson/achaolgson.component';
import { OlgsonbarmtComponent } from './olgsonbarmt/olgsonbarmt.component';
import { AvlgaComponent } from './avlga/avlga.component';
import { TaviurComponent } from './taviur/taviur.component';
import { EreenburtComponent } from './ereenburt/ereenburt.component';
import { EreenirsenComponent } from './ereenirsen/ereenirsen.component';
import { HurgeltComponent } from './hurgelt/hurgelt.component';
import { HursongohComponent } from './hursongoh/hursongoh.component';
import { UserComponent } from './user/user.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/achaaburt' },
  { path: 'achaaburt', component: AchburtComponent },
  { path: 'achaahuv', component: AchhuvComponent },
  { path: 'huviargui', component: NohuvachaComponent },
  { path: 'olgolt', component: AchaolgoltComponent },
  { path: 'olgoson', component: AchaolgsonComponent },
  { path: 'barimtud', component: OlgsonbarmtComponent },
  { path: 'avlaga', component: AvlgaComponent },
  { path: 'taviur', component: TaviurComponent },
  { path: 'ereen', component: EreenburtComponent },
  { path: 'ereendirsen', component: EreenirsenComponent },
  { path: 'hurgelt', component: HurgeltComponent },
  { path: 'hursongoh', component: HursongohComponent },
  { path: 'user', component: UserComponent },
  { path: 'nevtreh', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
