import { Component, OnInit, Input, TemplateRef, ViewContainerRef  } from '@angular/core';
import { IlgeemjService } from '../services/ilgeemj.service';
import { TavtransService } from '../services/tavtrans.service';
import { TaviurService } from '../services/taviur.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';


@Component({
  selector: 'app-achhuv',
  templateUrl: './achhuv.component.html',
  styleUrls: ['./achhuv.component.css']
})
export class AchhuvComponent implements OnInit {

  ilgemjlst: any;
  tavtrnslst: any;
  tavlst: any;
  VsbleBnk: boolean = false;

  startValue: Date | null = null;
  endValue: Date | null = null;
  btnlding = false;
  btnlding1 = false;

  ilgemjDta: any = {
    barcode: null,
    ognoo: null,
    utas: null,
    price: null,
    hemjee: null,
    userid: 1
  }

  slctbarcde = '';
  slctilgemj: any;


  ilgemjtav: any = {
    ilg_id: null,
    tav_id: null,
    ognoo: new Date(),
    note: null,
    userid: 1
  }



  byutasilgemj: any;


  constructor(private ilgemjSrvce: IlgeemjService, private modal: NzModalService, private viewContainerRef: ViewContainerRef, private notification: NzNotificationService, private tavTrnSrvce: TavtransService, private tavSrvce: TaviurService) { }

  ngOnInit(): void {
    this.ilgemjSrvce.getIlgemj().subscribe((lst) => (this.ilgemjlst = lst));
    this.tavTrnSrvce.getTavtrans().subscribe((lsttav) => (this.tavtrnslst = lsttav));
    this.tavSrvce.getTaviur().subscribe((tav) => (this.tavlst = tav));
    this.ilgemjDta.ognoo = new Date();
    this.ilgemjtav.ognoo = new Date();
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() <= this.startValue.getTime();
  };

  handleStartOpenChange(open: boolean): void {
    if (this.startValue && this.endValue) {
      // this.sales = this.sales.filter(
      //   (item) =>
      //     new Date(item.invdate) >= this.startValue &&
      //     new Date(item.invdate) <= this.endValue
      // );
    }
  }

  handleEndOpenChange(open: boolean): void {
    // if (this.startValue && this.endValue) {
    //   this.sales = this.sales.filter(
    //     (item) =>
    //       new Date(item.invdate) >= this.startValue &&
    //       new Date(item.invdate) <= this.endValue
    //   );
    // }
  }

  slctDternge(): void {
    // if (this.startValue && this.endValue) {
    //   this.borlultSrvce.getSales().subscribe((lst) => {
    //     this.sales = lst.filter(
    //       (item) =>
    //         new Date(item.invdate) >= this.startValue &&
    //         new Date(item.invdate) <= this.endValue
    //     );
    //   });
    // }
  }

  rstDteRnge(): void {
    this.startValue = null;
    this.endValue = null;
    // this.borlultSrvce.getSales().subscribe((lst) => (this.sales = lst));
  }


  handleCancel(): void {


    this.VsbleBnk = false;
  }


  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  selctfunc(event: any) {
    event.target.select();
  }

  newIlgemj(){
    this.VsbleBnk = true;
  }

  huvIlgemj(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>): void {
    if(this.slctbarcde) {
      this.slctilgemj = this.ilgemjlst.find((c: any) =>Number(c.barcode)===Number(this.slctbarcde));
      if(this.slctilgemj){
        this.modal.create({
          nzTitle: tplTitle,
          nzContent: tplContent,
          nzFooter: tplFooter,
          nzMaskClosable: false,
          nzClosable: false,
          // nzComponentParams: {
          //   value: 'Template Context'
          // },
          nzOnOk: () => console.log('Click ok')
        });
      } else {
        this.crteNtcn('warning', this.slctbarcde + '  кодтой ачаа байхгүй байна.', '');
        this.slctbarcde = '';
      }
    } else {

      this.crteNtcn('warning', 'Бар код бичээгүй байна.', '');

    }

  }



  hadhaah(modelRef: NzModalRef): void {
    this.ilgemjtav.ilg_id = +this.slctilgemj.ilg_id;
    if(this.ilgemjtav.ilg_id && this.ilgemjtav.tav_id ){
    this.btnlding = true;
    this.ilgemjtav.tav_id = +this.ilgemjtav.tav_id
    this.tavTrnSrvce.newTavtrans(this.ilgemjtav).subscribe(rslt =>{
      console.log(rslt);
      this.tavTrnSrvce.getTavtrans().subscribe(lst => {
        this.tavtrnslst = lst;
        console.log(this.tavtrnslst);
      });
      this.btnlding = false;
      this.crteNtcn('success', 'Амжилттай хадгалсан.', '');
      this.slctbarcde = '';
      this.ilgemjtav = {
        ilg_id: null,
        tav_id: null,
        ognoo: new Date(),
        note: null,
        userid: 1
      }

      modelRef.destroy();
    });

    } else {

      this.btnlding = false;
      this.crteNtcn('warning', 'Бүрэн бөглөх ёстой.', '');

    }

  }

  mdldestroy(modelRef: NzModalRef): void {
    this.slctbarcde = '';
    this.slctilgemj = null;
    this.ilgemjtav = {
      ilg_id: null,
      tav_id: null,
      ognoo: new Date(),
      note: null,
      userid: 1
    }
    modelRef.destroy();

  }

  hadgalah(): void {
    if(this.ilgemjDta.barcode && this.ilgemjDta.utas && this.ilgemjDta.price){
      this.btnlding1 = true;
      this.ilgemjDta.hemjee =  +this.ilgemjDta.hemjee;
      this.ilgemjDta.price =  +this.ilgemjDta.price;
    this.ilgemjSrvce.newIlgemj(this.ilgemjDta).subscribe(rslt=>{
      this.ilgemjSrvce.getIlgemj().subscribe((lst) => (this.ilgemjlst = lst))
      this.btnlding1 = false;
      this.crteNtcn('success', 'Амжилттай хадгалсан.', '');
      this.ilgemjDta = {
        barcode: null,
        ognoo: new Date(),
        utas: null,
        price: null,
        hemjee: null,
        userid: 1
      }
    });

    } else {
      this.btnlding1 = false;
      this.crteNtcn('warning', 'Бүрэн бөглөх ёстой.', '');

    }


  }




  ilgemjfind(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>): void {

    this.slctilgemj = this.ilgemjlst.find((c: any) =>c.barcode===this.slctbarcde);

    console.log(this.slctilgemj);

    if(this.slctilgemj){
      this.modal.create({
        nzTitle: tplTitle,
        nzContent: tplContent,
        nzFooter: tplFooter,
        nzMaskClosable: false,
        nzClosable: false,
        // nzComponentParams: {
        //   value: 'Template Context'
        // },
        nzOnOk: () => console.log('Click ok')
      });

      this.byutasilgemj = this.tavtrnslst.filter((ilg: any) => Number(ilg.utas)==Number(this.slctilgemj.utas));


    } else {
      this.crteNtcn('warning', this.slctbarcde + '  кодтой ачаа байхгүй байна.', '');
      this.slctbarcde = '';
    }

  }


  crteNtcn(type: string, title: string, message: string): void {
    this.notification.create(type,
      title,
      message
    );
  }




  tavbyutas(): void {
    if(this.byutasilgemj){
      this.byutasilgemj = null;
    } else {
      this.byutasilgemj = this.tavtrnslst.filter((rslt: any) => {Number(rslt.utas) === Number(this.slctilgemj.utas)});
      console.log(this.byutasilgemj);
    }


  }







}
