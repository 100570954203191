import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { IlgeemjService } from '../services/ilgeemj.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-ereenirsen',
  templateUrl: './ereenirsen.component.html',
  styleUrls: ['./ereenirsen.component.css']
})
export class EreenirsenComponent implements OnInit {




  hailt: any;
  ilgemjlst: any;

  constructor(private el: ElementRef, private ilgemjSrvce: IlgeemjService, private modal: NzModalService, private notification: NzNotificationService,) { }


  ngOnInit(): void {
    this.ilgemjSrvce.getEreendirsen().subscribe(rslt=>this.ilgemjlst = rslt)
  }




  findbycode(): any {

    // if(this.hailtuts) this.tavtrnslst1 = this.tavtrnslst;

        return  this.ilgemjlst ? this.ilgemjlst.filter((item: any) => item.barcode.search(new RegExp(this.hailt, 'i'))  > -1) : [];


      }








}
