import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import {
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzListModule } from 'ng-zorro-antd/list';

import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';

import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { AchburtComponent } from './achburt/achburt.component';
import { AchhuvComponent } from './achhuv/achhuv.component';
import { NohuvachaComponent } from './nohuvacha/nohuvacha.component';
import { AchaolgoltComponent } from './achaolgolt/achaolgolt.component';
import { UtasfiltPipe } from './utasfilt.pipe';
import { AchaolgsonComponent } from './achaolgson/achaolgson.component';
import { OlgsonbarmtComponent } from './olgsonbarmt/olgsonbarmt.component';
import { AvlgaComponent } from './avlga/avlga.component';
import { TaviurComponent } from './taviur/taviur.component';
import { EreenburtComponent } from './ereenburt/ereenburt.component';
import { ExcelimportDirective } from './excelimport.directive';
import { EreenirsenComponent } from './ereenirsen/ereenirsen.component';
import { HurgeltComponent } from './hurgelt/hurgelt.component';
import { HursongohComponent } from './hursongoh/hursongoh.component';
import { UserComponent } from './user/user.component';
import { LoginComponent } from './login/login.component';
import { NzIconModule } from 'ng-zorro-antd/icon';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    AchburtComponent,
    AchhuvComponent,
    NohuvachaComponent,
    AchaolgoltComponent,
    UtasfiltPipe,
    AchaolgsonComponent,
    OlgsonbarmtComponent,
    AvlgaComponent,
    TaviurComponent,
    EreenburtComponent,
    ExcelimportDirective,
    EreenirsenComponent,
    HurgeltComponent,
    HursongohComponent,
    UserComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzAvatarModule,
    NzDropDownModule,
    NzToolTipModule,
    NzBreadCrumbModule,
    NzDividerModule,
    NzFormModule,
    NzDescriptionsModule,
    NzButtonModule,
    NzInputModule,
    NzGridModule,
    NzDatePickerModule,
    NzTableModule,
    NzPageHeaderModule,
    NzTabsModule,
    NzTypographyModule,
    NzSelectModule,
    NzSpinModule,
    NzDrawerModule,
    NzSwitchModule,
    NzNotificationModule,
    NzModalModule,
    NzUploadModule,
    NzCollapseModule,
    NzStatisticModule,
    NzListModule,
    NzIconModule,
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }],
  bootstrap: [AppComponent],
})
export class AppModule {}
