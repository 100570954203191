import { Component, OnInit, Input, TemplateRef, ViewContainerRef  } from '@angular/core';
import { IlgeemjService } from '../services/ilgeemj.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
@Component({
  selector: 'app-nohuvacha',
  templateUrl: './nohuvacha.component.html',
  styleUrls: ['./nohuvacha.component.css']
})
export class NohuvachaComponent implements OnInit {

  ilgemjlst: any;
  VsbleBnk: boolean = false;

  startValue: Date | null = null;
  endValue: Date | null = null;
  btnlding = false;
  btnlding1 = false;

  ilgemjDta: any = {
    barcode: null,
    ognoo: null,
    utas: null,
    price: null,
    hemjee: null,
    userid: 1
  }

  constructor(private ilgemjSrvce: IlgeemjService, private modal: NzModalService, private viewContainerRef: ViewContainerRef, private notification: NzNotificationService,) { }

  ngOnInit(): void {
    this.ilgemjSrvce.getNohuvIlgemj().subscribe((lst) => (this.ilgemjlst = lst));
    this.ilgemjDta.ognoo = new Date();
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() <= this.startValue.getTime();
  };

  handleStartOpenChange(open: boolean): void {
    if (this.startValue && this.endValue) {
      // this.sales = this.sales.filter(
      //   (item) =>
      //     new Date(item.invdate) >= this.startValue &&
      //     new Date(item.invdate) <= this.endValue
      // );
    }
  }

  handleEndOpenChange(open: boolean): void {
    // if (this.startValue && this.endValue) {
    //   this.sales = this.sales.filter(
    //     (item) =>
    //       new Date(item.invdate) >= this.startValue &&
    //       new Date(item.invdate) <= this.endValue
    //   );
    // }
  }

  slctDternge(): void {
    // if (this.startValue && this.endValue) {
    //   this.borlultSrvce.getSales().subscribe((lst) => {
    //     this.sales = lst.filter(
    //       (item) =>
    //         new Date(item.invdate) >= this.startValue &&
    //         new Date(item.invdate) <= this.endValue
    //     );
    //   });
    // }
  }

  rstDteRnge(): void {
    this.startValue = null;
    this.endValue = null;
    // this.borlultSrvce.getSales().subscribe((lst) => (this.sales = lst));
  }


  handleCancel(): void {


    this.VsbleBnk = false;
  }


  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  selctfunc(event: any) {
    event.target.select();
  }

  newIlgemj(){
    this.VsbleBnk = true;
  }

  addIlgemj(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>): void {
    this.modal.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      // nzComponentParams: {
      //   value: 'Template Context'
      // },
      nzOnOk: () => console.log('Click ok')
    });
  }

  hadhaah(modelRef: NzModalRef): void {

    if(this.ilgemjDta.barcode && this.ilgemjDta.utas && this.ilgemjDta.price){
      this.btnlding = true;
      this.ilgemjDta.hemjee =  +this.ilgemjDta.hemjee;
      this.ilgemjDta.price =  +this.ilgemjDta.price;
    this.ilgemjSrvce.newIlgemj(this.ilgemjDta).subscribe(rslt=>{
      this.ilgemjSrvce.getIlgemj().subscribe((lst) => (this.ilgemjlst = lst))
      this.btnlding = false;
      this.crteNtcn('success', 'Амжилттай хадгалсан.', '');
      this.ilgemjDta = {
        barcode: null,
        ognoo: new Date(),
        utas: null,
        price: null,
        hemjee: null,
        userid: 1
      }
      modelRef.destroy();
    });

    } else {
      this.btnlding = false;
      this.crteNtcn('warning', 'Бүрэн бөглөх ёстой.', '');

    }


  }

  hadgalah(): void {
    if(this.ilgemjDta.barcode && this.ilgemjDta.utas && this.ilgemjDta.price){
      this.btnlding1 = true;
      this.ilgemjDta.hemjee =  +this.ilgemjDta.hemjee;
      this.ilgemjDta.price =  +this.ilgemjDta.price;
    this.ilgemjSrvce.newIlgemj(this.ilgemjDta).subscribe(rslt=>{
      this.ilgemjSrvce.getIlgemj().subscribe((lst) => (this.ilgemjlst = lst))
      this.btnlding1 = false;
      this.crteNtcn('success', 'Амжилттай хадгалсан.', '');
      this.ilgemjDta = {
        barcode: null,
        ognoo: new Date(),
        utas: null,
        price: null,
        hemjee: null,
        userid: 1
      }
    });

    } else {
      this.btnlding1 = false;
      this.crteNtcn('warning', 'Бүрэн бөглөх ёстой.', '');

    }


  }



  crteNtcn(type: string, title: string, message: string): void {
    this.notification.create(type,
      title,
      message
    );
  }





}
