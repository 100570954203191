import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { ZuragService } from '../services/zurag.service';
import { I18NHtmlParser } from '@angular/compiler';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
})
export class UserComponent implements OnInit {
  usrfltr: any;
  newusrcble = false;
  loadingBtn = false;

  Userlist: any;
  loading = false;
  lggedusr: any;
  frmData: any = {
    ner: '',
    ovog: '',
    utas: '',
    email: '',
    rolid: 1,
    username: '',
    password: '',
    zurag: null,
  };

  roles: any = [
    {
      id: 1,
      ner: 'Админ',
    },
    {
      id: 2,
      ner: 'Борлуулагч',
    },
    {
      id: 3,
      ner: 'Нягтлан бодогч',
    },
  ];

  newZur: any = {
    id: null,
    oldzur: null,
    zurag: null,
  };

  selectedFile: any;
  imgURL: any;
  blobs: any;
  cvtfle: any;
  upldedZrg: any;

  constructor(
    private usrSrvce: UserService,
    private zrgSrvce: ZuragService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.chkLgdUsr();
    this.usrSrvce.getUserrol().subscribe((lst) => (this.Userlist = lst));
  }

  showNewcust(): void {
    this.newusrcble = true;
  }

  closeNewcust(): void {
    this.newusrcble = false;
    this.frmData = {
      ner: '',
      ovog: '',
      utas: '',
      email: '',
      rolid: 1,
      username: '',
      password: '',
      zurag: null,
    };
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  slctfocus(event: any) {
    event.target.select();
  }

  chkLgdUsr(): void {
    if (this.getUser()) {
      this.lggedusr = this.getUser();
    } else {
      this.router.navigate(['/nevtreh']);
    }
  }

  getUser(): any {
    const item = window.localStorage.getItem('amar');
    return item ? JSON.parse(item) : null;
  }
}
