import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HurgeltService {
  constructor(private httpsrvce: HttpClient) {}

  hurgeltsongoh(frm: any): Observable<any> {
    const body = JSON.stringify(frm);
    const catoption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    console.log(body);

    return this.httpsrvce.post<any>(
      'https://amarcargoapi.amar.ac/api/hurgelt',
      body,
      catoption
    );
  }

  getHurgeltlst(): Observable<any[]> {
    return this.httpsrvce.get<any[]>(
      'https://amarcargoapi.amar.ac/api/hurgeltlst'
    );
  }

  getDrivers(): Observable<any[]> {
    return this.httpsrvce.get<any[]>(
      'https://amarcargoapi.amar.ac/api/drivers'
    );
  }
  getHurgelted(): Observable<any[]> {
    return this.httpsrvce.get<any[]>(
      'https://amarcargoapi.amar.ac/api/hurgelted'
    );
  }
  hurgeltgargah(frm: any): Observable<any> {
    const body = JSON.stringify(frm);
    const catoption = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    console.log(body);

    return this.httpsrvce.post<any>(
      'https://amarcargoapi.amar.ac/api/hurgelttrip',
      body,
      catoption
    );
  }
}
