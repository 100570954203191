import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utasfilt'
})
export class UtasfiltPipe implements PipeTransform {


  transform(list: any[], hailtutas: string): any {

    return list ? list.filter(item => item.utas.search(new RegExp(hailtutas, 'i'))  > -1) : [];
  }

}
