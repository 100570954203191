import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ElementRef,
  HostListener,
} from '@angular/core';
import { IlgeemjService } from '../services/ilgeemj.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ExcelService } from '../services/excel.service';
import { format, formatISO } from 'date-fns';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { TaviurService } from '../services/taviur.service';
import {} from 'date-fns';
@Component({
  selector: 'app-achburt',
  templateUrl: './achburt.component.html',
  styleUrls: ['./achburt.component.css'],
})
export class AchburtComponent implements OnInit {
  @ViewChild('taviur') myInputField: any;
  hailtutas: any;
  barcode: any;
  ilgemjlst: any;
  VsbleBnk: boolean = false;
  taviurlst: any;

  startValue: Date | null = null;
  endValue: Date | null = null;
  btnlding = false;
  btnlding1 = false;

  ilgemjDta: any = {
    barcode: null,
    ognoo: null,
    utas: null,
    price: null,
    hemjee: null,
    userid: 1,
    hemneg: 1,
    note: null,
    taviur: 0,
  };

  ilgemjUpdate: any = {
    barcode: null,
    ognoo: null,
    utas: null,
    price: null,
    hemjee: null,
    userid: 1,
    hemneg: 1,
    note: null,
  };

  hemnegj: any = [
    {
      id: 1,
      nme: 'Киллограм',
    },
    {
      id: 2,
      nme: 'Куб мер',
    },
  ];

  VsbleDel = false;
  loadingDel = false;
  selIlgeemj: any;

  ognoo: any;
  lggedusr: any;
  editIlgemj = false;
  constructor(
    private el: ElementRef,
    private ilgemjSrvce: IlgeemjService,
    private modal: NzModalService,
    private notification: NzNotificationService,
    private excelService: ExcelService,
    private router: Router,
    private taviurSrvce: TaviurService
  ) {}

  ngOnInit(): void {
    this.chkLgdUsr();

    this.ilgemjSrvce.getIlgemj().subscribe((lst) => (this.ilgemjlst = lst));
    this.ilgemjDta.ognoo = new Date();
    this.ilgemjSrvce
      .getLstHemnegj()
      .subscribe((lst) => (this.ilgemjDta.hemneg = lst.hemnegj));
    this.taviurSrvce.getTaviur().subscribe((lst) => (this.taviurlst = lst));
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 17) {
      event.returnValue = false;
      event.preventDefault();
    }
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() <= this.startValue.getTime();
  };

  handleStartOpenChange(open: boolean): void {
    if (this.startValue && this.endValue) {
      // this.sales = this.sales.filter(
      //   (item) =>
      //     new Date(item.invdate) >= this.startValue &&
      //     new Date(item.invdate) <= this.endValue
      // );
    }
  }

  handleEndOpenChange(open: boolean): void {
    // if (this.startValue && this.endValue) {
    //   this.sales = this.sales.filter(
    //     (item) =>
    //       new Date(item.invdate) >= this.startValue &&
    //       new Date(item.invdate) <= this.endValue
    //   );
    // }
  }

  slctDternge(): void {
    // if (this.startValue && this.endValue) {
    //   this.borlultSrvce.getSales().subscribe((lst) => {
    //     this.sales = lst.filter(
    //       (item) =>
    //         new Date(item.invdate) >= this.startValue &&
    //         new Date(item.invdate) <= this.endValue
    //     );
    //   });
    // }
  }

  rstDteRnge(): void {
    this.startValue = null;
    this.endValue = null;
    // this.borlultSrvce.getSales().subscribe((lst) => (this.sales = lst));
  }

  handleCancel(): void {
    this.VsbleBnk = false;
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  barCode(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 32) return true;
    if (48 <= charCode && charCode <= 57) return true;
    if (65 <= charCode && charCode <= 90) return true;
    if (97 <= charCode && charCode <= 122) return true;
    return false;
  }

  selctfunc(event: any) {
    event.target.select();
  }

  newIlgemj() {
    this.VsbleBnk = true;
  }

  addIlgemj(
    tplTitle: TemplateRef<{}>,
    tplContent: TemplateRef<{}>,
    tplFooter: TemplateRef<{}>
  ): void {
    this.modal.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      // nzComponentParams: {
      //   value: 'Template Context',
      // },
      nzOnOk: () => console.log('Click ok'),
    });
    console.log(this.ilgemjDta);
  }

  hadhaah(modelRef: NzModalRef): void {
    if (
      this.ilgemjlst.filter(
        (rslt: any) => rslt.barcode === this.ilgemjDta.barcode
      ).length === 0
    ) {
      if (
        this.ilgemjDta.barcode &&
        this.ilgemjDta.utas &&
        this.ilgemjDta.price
      ) {
        this.btnlding = true;
        this.ilgemjDta.hemneg = +this.ilgemjDta.hemneg;
        this.ilgemjDta.hemjee = +this.ilgemjDta.hemjee;
        this.ilgemjDta.price = +this.ilgemjDta.price;
        this.ilgemjDta.taviur = +this.ilgemjDta.taviur;
        this.ilgemjDta.ognoo = format(this.ilgemjDta.ognoo, 'yyyy-MM-dd');

        this.ilgemjSrvce.newIlgemj(this.ilgemjDta).subscribe((rslt) => {
          this.ilgemjSrvce
            .delereen(this.ilgemjDta.barcode)
            .subscribe((rslt) => console.log(rslt));
          this.ilgemjSrvce
            .upLsthemnegj({ hemnegj: this.ilgemjDta.hemneg })
            .subscribe((res) => {
              this.ilgemjSrvce
                .getLstHemnegj()
                .subscribe((lst) => (this.ilgemjDta.hemneg = lst.hemnegj));
            });
          this.ilgemjSrvce
            .getIlgemj()
            .subscribe((lst) => (this.ilgemjlst = lst));
          this.btnlding = false;
          this.crteNtcn('success', 'Амжилттай хадгалсан.', '');
          this.ilgemjDta = {
            barcode: null,
            ognoo: new Date(),
            utas: null,
            price: null,
            hemjee: null,
            userid: 1,
            hemneg: +this.ilgemjDta.hemneg,
            note: null,
          };
          modelRef.destroy();
        });
      } else {
        this.btnlding = false;
        this.crteNtcn('warning', 'Бүрэн бөглөх ёстой.', '');
      }
    }
  }

  hadgalah(): void {
    if (
      this.ilgemjlst.filter(
        (rslt: any) => rslt.barcode === this.ilgemjDta.barcode
      ).length === 0
    ) {
      if (
        this.ilgemjDta.barcode &&
        this.ilgemjDta.utas &&
        this.ilgemjDta.price
      ) {
        this.btnlding1 = true;
        this.ilgemjDta.hemneg = +this.ilgemjDta.hemneg;
        this.ilgemjDta.hemjee = +this.ilgemjDta.hemjee;
        this.ilgemjDta.price = +this.ilgemjDta.price;
        this.ilgemjDta.taviur = +this.ilgemjDta.taviur;
        this.ilgemjDta.ognoo = format(this.ilgemjDta.ognoo, 'yyyy-MM-dd');

        this.ilgemjSrvce.newIlgemj(this.ilgemjDta).subscribe((rslt) => {
          this.ilgemjSrvce
            .delereen(this.ilgemjDta.barcode)
            .subscribe((rslt) => console.log(rslt));
          this.ilgemjSrvce
            .upLsthemnegj({ hemnegj: this.ilgemjDta.hemneg })
            .subscribe((res) => {
              this.ilgemjSrvce
                .getLstHemnegj()
                .subscribe((lst) => (this.ilgemjDta.hemneg = lst?.hemnegj));
            });
          this.ilgemjSrvce
            .getIlgemj()
            .subscribe((lst) => (this.ilgemjlst = lst));
          this.btnlding1 = false;
          this.crteNtcn('success', 'Амжилттай хадгалсан.', '');
          this.ilgemjDta = {
            barcode: null,
            ognoo: new Date(),
            utas: this.ilgemjDta.utas,
            price: null,
            hemjee: this.ilgemjDta.hemjee,
            userid: 1,
            hemneg: 2,
            note: null,
            taviur: this.ilgemjDta.taviur,
          };
          this.setfocus();
        });
      } else {
        this.btnlding1 = false;
        this.crteNtcn('warning', 'Бүрэн бөглөх ёстой.', '');
      }
    }
  }

  crteNtcn(type: string, title: string, message: string): void {
    this.notification.create(type, title, message);
  }

  dsble(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 17 || charCode == 74) {
      charCode.preventDefault();
    }
    console.log(charCode);
  }

  utas1(utas: any) {
    // if( (<HTMLInputElement>document.getElementById("barcde")).value) {
    //   utas.focus();
    // }

    if (
      this.ilgemjlst.filter(
        (rslt: any) => rslt.barcode == this.ilgemjDta.barcode
      ).length === 0
    ) {
      utas.focus();
      utas.select();
    } else {
      this.crteNtcn('warning', 'Энэ ачааг өмнө нь хадгалсан байна.', '');
      (<HTMLInputElement>document.getElementById('barcde')).focus();
      (<HTMLInputElement>document.getElementById('barcde')).select();
    }
  }
  taviur1(taviur: any) {
    if (this.ilgemjDta.utas) {
      taviur.select();
      this.ilgemjDta.taviur = this.ilgemjlst?.filter(
        (res: any) => res.utas === this.ilgemjDta.utas
      )[0]?.taviur;
      taviur.focus();
      taviur.select();
    }
  }
  hemjee1(hemjee: any) {
    if (this.ilgemjDta.utas) {
      hemjee.focus();
      hemjee.select();
    }
  }

  une1(une: any) {
    if (this.ilgemjDta.hemjee) une.focus();
    une.select();
  }

  note1(note: any) {
    if (this.ilgemjDta.price) note.focus();
    note.select();
  }

  setfocus(): void {
    (<HTMLInputElement>document.getElementById('barcde')).focus();
  }

  cubMetr(
    tplTitle: TemplateRef<{}>,
    tplContent: TemplateRef<{}>,
    tplFooter: TemplateRef<{}>
  ): void {
    this.modal.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      // nzComponentParams: {
      //   value: 'Template Context',
      // },
      nzOnOk: () => console.log('Click ok'),
    });
  }

  Kilogram(): void {}

  findbycode(): any {
    if (this.barcode)
      return this.ilgemjlst
        ? this.ilgemjlst.filter(
            (item: any) =>
              item.barcode.search(new RegExp(this.barcode, 'i')) > -1
          )
        : [];

    if (this.hailtutas)
      return this.ilgemjlst
        ? this.ilgemjlst.filter(
            (item: any) =>
              item.utas.search(new RegExp(this.hailtutas, 'i')) > -1
          )
        : [];
    if (this.ognoo)
      return this.ilgemjlst
        ? this.ilgemjlst.filter(
            (item: any) =>
              moment(item.ognoo).format('DD/MM/YYYY') ==
              moment(this.ognoo).format('DD/MM/YYYY')
          )
        : [];

    if (!this.hailtutas && !this.barcode && !this.ognoo) return this.ilgemjlst;
  }

  onChange(result: Date): void {
    this.findbycode();
    console.log(result);
    console.log(format(result, 'yyyy/MM/dd'));
    return this.ilgemjlst
      ? this.ilgemjlst.filter(
          (item: any) => item.ognoo == format(result, 'yyyy/MM/dd')
        )
      : [];
  }

  showDel(item: any): void {
    this.VsbleDel = true;
    this.selIlgeemj = item;
  }

  confirm(): void {
    this.loadingDel = true;
    if (this.selIlgeemj.barcode) {
      this.ilgemjSrvce.delIlgeemj(this.selIlgeemj.barcode).subscribe((rslt) => {
        console.log(rslt);
        if (Number(rslt) > 0) {
          this.ilgemjSrvce
            .getIlgemj()
            .subscribe((lst) => (this.ilgemjlst = lst));
          this.loadingDel = false;
          this.crteNtcn('success', 'Амжилттай устгасан.', '');
          this.VsbleDel = false;
          this.selIlgeemj = null;
        }
      });
    } else {
      this.loadingDel = false;
      this.crteNtcn('warning', 'Устгасангүй.', '');
      this.VsbleDel = false;
      this.selIlgeemj = null;
    }
  }

  cancel(): void {
    this.VsbleDel = false;
    this.loadingDel = false;
    this.selIlgeemj = null;
    this.ilgemjDta.barcode = null;
  }

  // showUpdate(item: any): void{
  //   console.log(item)

  // }

  showUpdate(
    item: any,
    tplTitle: TemplateRef<{}>,
    tplContent: TemplateRef<{}>,
    tplFooter: TemplateRef<{}>
  ): void {
    this.modal.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzMaskClosable: false,
      nzClosable: false,
      // nzComponentParams: {
      //   value: 'Template Context',
      // },
      nzOnOk: () => console.log('Click ok'),
    });
    this.ilgemjUpdate = item;
  }

  utas2(utas: any) {
    if ((<HTMLInputElement>document.getElementById('barcde')).value) {
      utas.focus();
    }
  }
  hemjee2(hemjee: any) {
    if (this.ilgemjUpdate.utas) {
      hemjee.focus();
    }
  }

  une2(une: any) {
    if (this.ilgemjUpdate.hemjee) une.focus();
  }

  note2(note: any) {
    if (this.ilgemjUpdate.price) note.focus();
  }

  hadhaah2(modelRef: NzModalRef): void {
    if (
      this.ilgemjUpdate.barcode &&
      this.ilgemjUpdate.utas &&
      this.ilgemjUpdate.price
    ) {
      this.btnlding = true;
      this.ilgemjUpdate.hemneg = +this.ilgemjUpdate.hemneg;
      this.ilgemjUpdate.hemjee = +this.ilgemjUpdate.hemjee;
      this.ilgemjUpdate.price = +this.ilgemjUpdate.price;

      this.ilgemjSrvce.UpIlgeemj(this.ilgemjUpdate).subscribe((rslt) => {
        //this.ilgemjSrvce.delereen(this.ilgemjDta.barcode).subscribe(rslt=>console.log(rslt));

        this.ilgemjSrvce.getIlgemj().subscribe((lst) => (this.ilgemjlst = lst));
        this.btnlding = false;
        this.crteNtcn('success', 'Амжилттай хадгалсан.', '');
        this.ilgemjUpdate = {
          barcode: null,
          ognoo: formatDate(new Date(), 'yyyy/MM/dd', 'en-US'),
          utas: null,
          price: null,
          hemjee: null,
          userid: 1,
          hemneg: 1,
          note: null,
        };
        modelRef.destroy();
      });
    } else {
      this.btnlding = false;
      this.crteNtcn('warning', 'Бүрэн бөглөх ёстой.', '');
    }
  }

  sumCheck(): number {
    return this.findbycode()
      ? this.findbycode()
          .map((item: any) => item.price)
          .reduce((a: any, b: any) => a + b, 0)
      : null;
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(
      this.findbycode()?.sort(this.compareValues('utas')),
      'achaa'
    );
  }

  compareValues(key: any, order = 'asc') {
    return function innerSort(a: any, b: any) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }
  focBarcode() {
    this.barcode = '';
    this.findbycode();
  }
  focUtas() {
    this.hailtutas = '';
    this.findbycode();
  }
  chkLgdUsr(): void {
    if (this.getUser()) {
      this.lggedusr = this.getUser();
    } else {
      this.router.navigate(['/nevtreh']);
    }
  }

  getUser(): any {
    const item = window.localStorage.getItem('amar');
    return item ? JSON.parse(item) : null;
  }
}
