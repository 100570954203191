<div class="site-page-header-ghost-wrapper">
  <nz-page-header [nzGhost]="false" style="background-color: #9cf1ce; border-top-right-radius: 10px; border-top-left-radius: 10px;">
    <nz-page-header-title>Хувиарласан ачааны жагсаалт</nz-page-header-title>
    <!-- <nz-page-header-subtitle>This is a subtitle</nz-page-header-subtitle> -->
    <nz-page-header-extra style="display: flex;/* justify-content: flex-end; */">

      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input type="text" nz-input placeholder="Бар кодоор хайх..." style="min-width: 250px;" [(ngModel)]="slctbarcde" (keyup.enter)="ilgemjfind(tplTitle, tplContent, tplFooter)" maxlength="25" autofocus/>
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>
    <button nz-button nzType="primary" nzShape="round" class="round" (click)="huvIlgemj(tplTitle, tplContent, tplFooter)"><i nz-icon [nzType]="'plus'"></i>Тавиур</button>

    </nz-page-header-extra>

  </nz-page-header>
</div>

<div class="inner-content">
  <div nz-row [nzGutter]="{ xs: 4, sm: 6, md: 8, lg: 10 }">
    <div nz-col class="gutter-row" [nzSpan]="6"><div class="inner-box">
      <form nz-form nzLayout="vertical">
      <nz-form-item>
        <nz-form-label >Бар код</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input type="text" nz-input placeholder="Бар кодоор хайх" />
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button nz-button nzType="primary" (click)="rstDteRnge()"><i nz-icon nzType="search"></i></button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      </form>
    </div>
  </div>
    <div nz-col class="gutter-row" [nzSpan]="6"><div class="inner-box">
      <form nz-form nzLayout="vertical">
      <nz-form-item>
        <nz-form-label >Утас</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input type="text" nz-input placeholder="Утасны дугаараар хайх" />
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button nz-button nzType="primary" (click)="rstDteRnge()"><i nz-icon nzType="search"></i></button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      </form>
    </div>
  </div>
    <div nz-col class="gutter-row" [nzSpan]="5"><div class="inner-box">
      <form nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label >Огноо</nz-form-label>
          <!-- <nz-range-picker [nzFormat]="dateFormat" (nzOnCalendarChange)="daterng($event)"></nz-range-picker> -->

          <div nz-form nzLayout="inline">
            <nz-form-item style="display: inline-block;">
              <nz-date-picker
              [nzDisabledDate]="disabledStartDate"
              nzShowTime='false'
              nzFormat="yyyy/MM/dd"
              [(ngModel)]="startValue"
              nzPlaceHolder="Эхлэх"
              (nzOnOpenChange)="slctDternge()"
              style="width: 135px;"
              [ngModelOptions]="{standalone: true}">
          </nz-date-picker>
            <nz-date-picker
              #endDatePicker
              [nzDisabledDate]="disabledEndDate"
              nzShowTime="false"
              nzFormat="yyyy/MM/dd"
              [(ngModel)]="endValue"
              nzPlaceHolder="Сүүл"
              (nzOnOpenChange)="slctDternge()"
              style="width: 135px;"
              [ngModelOptions]="{standalone: true}"

            ></nz-date-picker>
            </nz-form-item>

          </div>



        </nz-form-item>
        </form>
      </div>
    </div>
    <div nz-col class="gutter-row" [nzSpan]="5">
      <div class="inner-box">
      <form nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label>Тавиур#</nz-form-label>
          <nz-form-control>


            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Сонгох..." name="taviur">
              <nz-option *ngFor="let tav of tavlst" nzLabel="{{tav.tavnme}}" nzValue="{{tav.tav_id}}"></nz-option>
            </nz-select>




          </nz-form-control>
        </nz-form-item>
        </form>
      </div>
    </div>
    <div nz-col class="gutter-row" [nzSpan]="2">
      <div class="inner-box">
      <form nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label></nz-form-label>
          <nz-button-group [nzSize]="'default'">
            <button nz-button nzType="primary" nz-tooltip nzTooltipTitle="PDF татах"><i nz-icon nzType="file-pdf"></i></button>
            <button nz-button nzType="primary" nz-tooltip nzTooltipTitle="Excel татах"><i nz-icon nzType="file-excel"></i></button>
          </nz-button-group>
        </nz-form-item>
        </form>
      </div>
    </div>
  </div>
  <nz-table #tavtrnslst1 [nzData]="tavtrnslst" nzSize="small" nzTableLayout="auto" [nzFrontPagination]="true" [nzPageSize]="20">

    <thead>
      <tr>
        <th>#</th>
        <th nzWidth="10%">Тавиур#</th>
        <th nzWidth="8%">Утас</th>
        <th nzWidth="12%">Бар код</th>
        <th>Огноо#</th>
        <th nzWidth="8%">Хэмжээ</th>
        <th nzWidth="8%">Үнэ</th>
        <th nzWidth="10%">Б/ажилтан</th>
        <th nzWidth="25%">Тайлбар</th>
        <!-- <th>Төлсөн</th> -->
        <!-- <th>Үлдэгдэл</th>
        <th>Ачуулсан</th> -->
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of tavtrnslst1.data let i = index">
        <td>{{i+1}}</td>
        <td>{{item.tavnme}}</td>
        <td>{{item.utas}}</td>
        <td>{{item.barcode}}</td>
        <td>{{item.ognoo | date: 'yyyy/MM/dd'}}</td>
        <td>{{item.hemjee}}</td>
        <td>{{item.price}} </td>
        <td>{{item.ner}}</td>
        <td>{{item.note}}</td>
        <td>
          <nz-button-group nzSize="small">
            <button nz-button nzType="primary" class="editbtn"><i nz-icon nzType="form" nz-tooltip nzTooltipTitle="Засварлах"></i></button>
            <button nz-button nzType="primary" class="editbtn"><i nz-icon nzType="delete" nz-tooltip nzTooltipTitle="Устгах"></i></button>
          </nz-button-group>
      </td>
      </tr>
    </tbody>
  </nz-table>
  <div class="spin" *ngIf="!ilgemjlst">
    <nz-spin nzSimple></nz-spin>
  </div>
</div>



<ng-template #tplTitle>
  <span style="color: #1890ff;" >Ачаа хувиарлах <i nz-icon nzType="arrow-right"></i>  <i nz-icon nzType="phone" nzTheme="outline"></i>{{slctilgemj?.utas}}, <i nz-icon nzType="barcode" nzTheme="outline"></i>{{slctilgemj?.barcode}}, ₮{{slctilgemj?.price}}</span>
</ng-template>
<ng-template #tplContent let-params>
  <div nz-row [nzGutter]="[8, 0]" >
    <div nz-col nzSpan="9" >
      <div nz-form nzLayout="vertical" >
        <nz-form-item >
          <nz-form-label>Тавиур#</nz-form-label>
          <nz-form-control>
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Сонгох..." name="taviur1" [(ngModel)]="ilgemjtav.tav_id" nzAutoFocus>
              <nz-option *ngFor="let tav of tavlst" nzLabel="{{tav.tavnme}}" nzValue="{{tav.tav_id}}"></nz-option>
            </nz-select>
          </nz-form-control>
          <nz-form-label style="margin-top: 4px;">Огноо</nz-form-label>
          <nz-form-control>
            <nz-date-picker [nzSize]="'default'" style="display: block;" [(ngModel)]="ilgemjtav.ognoo" name="ognoo"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      </div>
    <div nz-col nzSpan="15" style="margin-bottom: 0px; margin-top: 0px;">
      <div nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label >Тайлбар</nz-form-label>
          <nz-form-control>
            <textarea rows="4" nz-input placeholder="Энэ хэсэгт тайлбар бичнэ..." [(ngModel)]="ilgemjtav.note" name="note"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
      </div>

      <nz-divider nzText="Өмнөх хувиарласан ачаанууд" nzOrientation="left" style="font-size: 14px;"></nz-divider>

    <div nz-col nzSpan="24" style="margin-top: 10px;">
      <div nz-form nzLayout="vertical" >
        <nz-form-item >
          <nz-form-control *ngIf="byutasilgemj">
            <p *ngFor="let item of byutasilgemj"><i nz-icon nzType="appstore" nzTheme="outline"> </i>{{item.tavnme}},   <i nz-icon nzType="phone" nzTheme="outline"></i> {{item.utas}}, <i nz-icon nzType="barcode" nzTheme="outline"></i> {{item.barcode}}, ₮{{item.price}}</p>
          </nz-form-control>
        </nz-form-item>
      </div>
      </div>



</div>




</ng-template>
<ng-template #tplFooter let-ref="modalRef">
  <div style="float: left"><button nz-button (click)="mdldestroy(ref)"><i nz-icon nzType="poweroff"></i>Хаах</button></div>

  <button nz-button nzType="primary" (click)="hadhaah(ref)" [nzLoading]="btnlding"><i nz-icon nzType="save"></i>Хадгалах</button>
</ng-template>






