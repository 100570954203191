import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  isCollapsed = false;
  log(data: string): void {
    console.log(data);
  }
  lggedusr: any;

  cmpny: any;

  constructor(private router: Router, private usrSrvce: UserService) {}
  ngOnInit(): void {
    this.chkLgdUsr();
    this.usrSrvce.getCmpny().subscribe((rslt) => (this.cmpny = rslt));
  }

  loggedUsrNer(): any {
    if (this.getUser()) {
      return this.getUser().ner;
    }
  }
  loggedUsrZur(): any {
    if (this.getUser()) {
      return this.getUser().zurag;
    }
  }

  logout(): void {
    localStorage.removeItem('amar');

    this.router
      .navigateByUrl('/nevtreh', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/nevtreh']);
      });
  }
  getUser(): any {
    const item = window.localStorage.getItem('amar');
    return item ? JSON.parse(item) : null;
  }

  chkLgdUsr(): void {
    if (this.getUser()) {
      //console.log(this.getUser());
      this.lggedusr = this.getUser();
      if (!this.lggedusr) this.router.navigate(['/nevtreh']);
      //this.router.navigate(['/olgolt']);
    }
  }
}
