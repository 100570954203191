import { Component, OnInit, Input, TemplateRef, ViewContainerRef  } from '@angular/core';
import { IlgeemjService } from '../services/ilgeemj.service';
import { BanktransService } from '../services/banktrans.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-olgsonbarmt',
  templateUrl: './olgsonbarmt.component.html',
  styleUrls: ['./olgsonbarmt.component.css']
})
export class OlgsonbarmtComponent implements OnInit {

  ilgemjlst: any;
  VsbleBnk: boolean = false;

  startValue: Date | null = null;
  endValue: Date | null = null;
  btnlding = false;
  btnlding1 = false;

  ilgemjDta: any = {
    barcode: null,
    ognoo: null,
    utas: null,
    price: null,
    hemjee: null,
    userid: 1
  }




  constructor(private ilgemjSrvce: IlgeemjService, private barmtSrvce: BanktransService, private modal: NzModalService, private viewContainerRef: ViewContainerRef, private notification: NzNotificationService,) { }

  ngOnInit(): void {
    this.barmtSrvce.getBarimt().subscribe((lst) => (this.ilgemjlst = lst));
    this.ilgemjDta.ognoo = new Date();
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() <= this.startValue.getTime();
  };

  handleStartOpenChange(open: boolean): void {
    if (this.startValue && this.endValue) {
      // this.sales = this.sales.filter(
      //   (item) =>
      //     new Date(item.invdate) >= this.startValue &&
      //     new Date(item.invdate) <= this.endValue
      // );
    }
  }

  handleEndOpenChange(open: boolean): void {
    // if (this.startValue && this.endValue) {
    //   this.sales = this.sales.filter(
    //     (item) =>
    //       new Date(item.invdate) >= this.startValue &&
    //       new Date(item.invdate) <= this.endValue
    //   );
    // }
  }

  slctDternge(): void {
    // if (this.startValue && this.endValue) {
    //   this.borlultSrvce.getSales().subscribe((lst) => {
    //     this.sales = lst.filter(
    //       (item) =>
    //         new Date(item.invdate) >= this.startValue &&
    //         new Date(item.invdate) <= this.endValue
    //     );
    //   });
    // }
  }

  rstDteRnge(): void {
    this.startValue = null;
    this.endValue = null;
    // this.borlultSrvce.getSales().subscribe((lst) => (this.sales = lst));
  }


  handleCancel(): void {


    this.VsbleBnk = false;
  }


  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  selctfunc(event: any) {
    event.target.select();
  }





  crteNtcn(type: string, title: string, message: string): void {
    this.notification.create(type,
      title,
      message
    );
  }




}
