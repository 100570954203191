<nz-layout>
  <nz-content
    ><div class="site-page-header-ghost-wrapper">
      <nz-page-header
        [nzGhost]="false"
        style="background-color: #88d3ff; border-top-left-radius: 10px"
      >
        <nz-page-header-title
          >Олгоход бэлэн ачааны жагсаалт</nz-page-header-title
        >
        <!-- <nz-page-header-subtitle>This is a subtitle</nz-page-header-subtitle> -->
        <nz-page-header-extra
          style="display: flex; /* justify-content: flex-end; */"
        >
          <nz-page-header-title style="color: dimgrey"
            >Ач/тоо:
            {{ findbyUtas()?.length | number : "1.0-2" }}</nz-page-header-title
          >
          <nz-page-header-title style="color: dimgrey"
            >Ач/дүн: {{ sumCheck() | number : "1.0-2" }}</nz-page-header-title
          >
        </nz-page-header-extra>
      </nz-page-header>
    </div>

    <div class="inner-content">
      <div nz-row [nzGutter]="{ xs: 4, sm: 6, md: 8, lg: 10 }">
        <div nz-col class="gutter-row" [nzSpan]="6">
          <div class="inner-box">
            <form nz-form nzLayout="vertical">
              <nz-form-item>
                <nz-form-label>Бар код</nz-form-label>
                <nz-form-control>
                  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input
                      type="text"
                      nz-input
                      placeholder="Бар кодоор хайх"
                      type="text"
                      nz-input
                      placeholder="Бар кодоор хайх"
                      [(ngModel)]="barcode"
                      name="barcode"
                      (blur)="focBarcode()"
                    />
                  </nz-input-group>
                  <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" (click)="rstDteRnge()">
                      <i nz-icon nzType="search"></i>
                    </button>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </form>
          </div>
        </div>
        <div nz-col class="gutter-row" [nzSpan]="5">
          <div class="inner-box">
            <form nz-form nzLayout="vertical">
              <nz-form-item>
                <nz-form-label>Утас</nz-form-label>
                <nz-form-control>
                  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                    <input
                      type="text"
                      nz-input
                      placeholder="Утасны дугаараар хайх"
                      [(ngModel)]="hailtuts"
                      (input)="findbyUtas()"
                      name="hailtuts"
                      (blur)="(focUtas)"
                    />
                  </nz-input-group>
                  <ng-template #suffixIconButton>
                    <button nz-button nzType="primary" (click)="rstDteRnge()">
                      <i nz-icon nzType="search"></i>
                    </button>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </form>
          </div>
        </div>

        <div nz-col class="gutter-row" [nzSpan]="2">
          <div class="inner-box">
            <form nz-form nzLayout="vertical">
              <nz-form-item>
                <nz-form-label></nz-form-label>
                <nz-button-group [nzSize]="'default'">
                  <!-- <button nz-button nzType="primary" nz-tooltip nzTooltipTitle="PDF татах"><i nz-icon nzType="file-pdf"></i></button>
                <button nz-button nzType="primary" nz-tooltip nzTooltipTitle="Excel татах"><i nz-icon nzType="file-excel"></i></button> -->
                </nz-button-group>
              </nz-form-item>
            </form>
          </div>
        </div>
      </div>
      <nz-table
        #tavtrnslst3
        [nzData]="findbyUtas()"
        (nzCurrentPageDataChange)="currentPageDataChange($event)"
        (nzPageIndexChange)="refreshStatus()"
        (nzPageSizeChange)="refreshStatus()"
        nzSize="small"
        nzTableLayout="auto"
        [nzFrontPagination]="true"
        [nzPageSize]="20"
      >
        <thead>
          <tr>
            <th>#</th>
            <th nzWidth="10px">Тавиур</th>
            <th nzWidth="8%">Утас</th>
            <th nzWidth="12%">Бар код</th>
            <th>Бүртгэсэн огноо</th>

            <th nzWidth="8%">Хэмжээ</th>
            <th nzWidth="8%">Үнэ</th>
            <th nzWidth="10%">Б/ажилтан</th>
            <th nzWidth="25%">Тайлбар</th>
            <th
              nzWidth="60px"
              nzShowCheckbox
              [(nzChecked)]="allChecked"
              [nzIndeterminate]="indeterminate"
              (nzCheckedChange)="checkAll($event)"
            ></th>
            <!-- <th>Төлсөн</th> -->
            <!-- <th>Үлдэгдэл</th>
            <th>Ачуулсан</th> -->
            <th>
              <a
                nz-button
                nzType="link"
                (click)="selAll()"
                *ngIf="selAllbtn()"
                style="padding: 0px 0px"
                >{{ selNmbr() }} ачаа</a
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of tavtrnslst3.data; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ item.taviur }}</td>
            <td>{{ item.utas }}</td>
            <td>{{ item.barcode }}</td>
            <td>{{ item.ognoo | date : "yyyy/MM/dd" }}</td>
            <td>{{ item.hemjee }}</td>
            <td>{{ item.price }}</td>
            <td>{{ item.ner }}</td>
            <td>{{ item.note }}</td>
            <td
              nzShowCheckbox
              [(nzChecked)]="item.olgoson"
              (nzCheckedChange)="refreshStatus()"
            ></td>
            <td>
              <nz-button-group nzSize="small">
                <button
                  nz-button
                  nzType="primary"
                  class="editbtn"
                  (click)="choseAcha(item.barcode)"
                >
                  <i
                    nz-icon
                    nzType="check"
                    nz-tooltip
                    nzTooltipTitle="Олгох"
                  ></i>
                </button>
              </nz-button-group>
            </td>
          </tr>
        </tbody>
      </nz-table>
      <div class="spin" *ngIf="!ilgemjlst">
        <nz-spin nzSimple></nz-spin>
      </div>
    </div>

    <ng-template #tplTitle>
      <span style="color: #1890ff"
        >Баримт хадгалах <i nz-icon nzType="arrow-right"></i>
        <i nz-icon nzType="phone" nzTheme="outline"></i>
        {{ olgoltLst[0].utas }},
        <i nz-icon nzType="appstore" nzTheme="outline"></i>
        {{ olgoltLst.length }} ачаа, ₮{{
          olsumCheck() | number : "1.0-2"
        }}</span
      >
    </ng-template>
    <ng-template #tplContent let-params>
      <div nz-row [nzGutter]="[8, 8]">
        <div nz-col nzSpan="3">
          <div nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-form-control> #{{ Dtaolgsn.olgsonids }} </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-col nzSpan="7">
          <div nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-form-control>
                <nz-date-picker
                  [nzSize]="'default'"
                  style="display: block"
                  [(ngModel)]="Dtaolgsn.ognoo"
                  name="ognoo"
                ></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-col nzSpan="7">
          <div nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  [value]="olsumCheck()"
                  [disabled]="true"
                  name="tolohdun"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-col nzSpan="7">
          <div nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  placeholder="Төлсөн дүн...."
                  (keypress)="numberOnly($event)"
                  (focus)="testfunc($event)"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-col nzSpan="24" style="margin-top: 5px">
          <div nz-form nzLayout="vertical">
            <nz-form-item>
              <nz-form-control>
                <input
                  nz-input
                  placeholder="Тайлбар..."
                  [(ngModel)]="Dtaolgsn.note"
                  name="note"
                  (focus)="testfunc($event)"
                />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #tplFooter let-ref="modalRef">
      <div style="float: left">
        <button nz-button (click)="mdldestroy(ref)">
          <i nz-icon nzType="poweroff"></i>Хаах
        </button>
      </div>

      <button
        nz-button
        nzType="primary"
        (click)="hadhaah(ref)"
        [nzLoading]="btnlding"
      >
        <i nz-icon nzType="save"></i>Хадгалах
      </button>
    </ng-template>
  </nz-content>

  <nz-sider
    nzCollapsible
    nzWidth="300px"
    nzBreakpoint="md"
    [nzTrigger]="null"
    style="height: 100vh; overflow-y: auto"
  >
    <div class="sidebar-logo">
      <div nz-row [nzGutter]="[0, 0]">
        <div nz-col nzSpan="12" style="color: dimgrey">
          <nz-statistic
            nzValue="{{ checkedLst?.length | number : '1.0-2' }}ш"
            [nzTitle]="'Ачаа тоо'"
          ></nz-statistic>
        </div>
        <div nz-col nzSpan="12" style="color: dimgrey">
          <nz-statistic
            nzValue="{{ barsumCheck() | number : '1.0-2' }}₮"
            [nzTitle]="'Ачаа дүн'"
          ></nz-statistic>
        </div>
      </div>
    </div>

    <div style="background: #cdf0d7">
      <div nz-row style="line-height: 0px; padding: 10px; background: #f1bf4a">
        <input
          nzBlock
          nz-input
          id="barcde"
          placeholder="Бар код..."
          [(ngModel)]="ilgemjDta.barcode"
          name="barcode"
          (focus)="selctfunc($event)"
          autocomplete="off"
          maxlength="25"
          autofocus
          (keyup.enter)="choseAcha1(barcode1)"
          #barcode1
        />
      </div>
    </div>

    <div nz-row [nzGutter]="[10, 10]" style="line-height: 0px; padding: 10px">
      <div nz-col nzSpan="24" style="color: dimgrey">
        <nz-select
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="Утас сонгох"
          style="width: 100%"
          [(ngModel)]="Dtaolgsn.utas"
          name="utas"
        >
          <nz-option
            *ngFor="let item of utasLst()"
            nzLabel="{{ item.utas }}  -> Ө.үлд  {{
              item.avsan - item.tolson || 0
            }}"
            nzValue="{{ item.utas }}"
          ></nz-option>
        </nz-select>
      </div>
      <!-- <div nz-col nzSpan="12" style="color:dimgrey">
          <h5 nz-typography >Өм.үлд: 1,000,000</h5>
        </div> -->

      <div nz-col nzSpan="12" style="color: dimgrey">
        <nz-form-item>
          <nz-form-label [nzSpan]="8">Данс</nz-form-label>
          <nz-form-control [nzSpan]="16">
            <input
              nz-input
              placeholder="данс"
              nz-tooltip
              nzTooltipTitle="Дансаар"
              [(ngModel)]="dtaBnk"
              (ngModelChange)="cashAmnt()"
              (keypress)="numberOnly($event)"
              (focus)="testfunc($event)"
              name="dans"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12" style="color: dimgrey">
        <nz-form-item>
          <nz-form-label [nzSpan]="8">Касс</nz-form-label>
          <nz-form-control [nzSpan]="16">
            <input
              nz-input
              placeholder="касс"
              nz-tooltip
              nzTooltipTitle="Бэлэн"
              [(ngModel)]="dtaCash"
              (ngModelChange)="bnkAmnt()"
              (keypress)="numberOnly($event)"
              (focus)="testfunc($event)"
              name="cash"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
      <!-- <div nz-col nzSpan="12" style="color:dimgrey;">
          <nz-form-item>
            <nz-form-label [nzSpan]="8">Зээл</nz-form-label>
              <nz-form-control [nzSpan]="16">
          <input nz-input placeholder="Basic usage" nz-tooltip nzTooltipTitle="Дараа тооцоо" [(ngModel)]="Zeel" (ngModelChange)="zeelAmnt()" (keypress)="numberOnly($event)" (focus)="testfunc($event)" name="zeel"/>
        </nz-form-control>
      </nz-form-item>
        </div> -->
      <div nz-col nzSpan="24" style="color: dimgrey">
        <button nz-button nzType="primary" nzBlock (click)="hadhaah1()">
          Олгох/Хадгалах
        </button>
      </div>
    </div>

    <!-- <nz-list>
      <nz-list-item
        *ngFor="let item of checkedLst"
        style="border-bottom: 1px solid #c0d4ce"
      >
        <nz-list-item-meta style="text-align: left; padding-left: 10px">
          <nz-list-item-meta-title>
            {{ item.utas }}
          </nz-list-item-meta-title>
          <nz-list-item-meta-description>
            {{ item.barcode }}
          </nz-list-item-meta-description>
        </nz-list-item-meta>
        {{ item.price }}
        <ul nz-list-item-actions>
          <nz-list-item-action>
            <a (click)="selectRem1(item)"
              ><i nz-icon nzType="delete" nzTheme="outline"></i></a
          ></nz-list-item-action>
        </ul>
      </nz-list-item>
    </nz-list> -->

    <nz-list>
      <nz-list-item
        *ngFor="let item of checkedLst"
        style="border-bottom: 1px solid #c0d4ce"
      >
        <nz-list-item-meta style="text-align: left; padding-left: 10px">
          <nz-list-item-meta-title>
            {{ item?.utas }}
          </nz-list-item-meta-title>
          <nz-list-item-meta-description>
            {{ item?.barcode }}
          </nz-list-item-meta-description>
        </nz-list-item-meta>
        {{ item?.price | number : "1.0-2" }}
        <ul nz-list-item-actions>
          <nz-list-item-action
            ><a (click)="selectRem1(item)"
              ><i nz-icon nzType="delete" nzTheme="outline"></i
            ></a>
          </nz-list-item-action>
        </ul>
      </nz-list-item>
    </nz-list>
  </nz-sider>
</nz-layout>
