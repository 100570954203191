<div class="site-page-header-ghost-wrapper">
  <nz-page-header
    [nzGhost]="false"
    style="
      background-color: #cff4d2;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    "
  >
    <nz-page-header-title>Системийн хэрэглэгчид</nz-page-header-title>
    <!-- <nz-page-header-subtitle>This is a subtitle</nz-page-header-subtitle> -->
    <nz-page-header-extra
      style="display: flex; /* justify-content: flex-end; */"
    >
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input
          type="text"
          nz-input
          placeholder="Нэр болон регистерээр хайх..."
          style="min-width: 250px"
          [(ngModel)]="usrfltr"
        />
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>

      <button
        nz-button
        nzType="primary"
        (click)="showNewcust()"
        style="margin-left: 10px"
      >
        <i nz-icon [nzType]="'plus'"></i>Хэрэглэгч
      </button>
    </nz-page-header-extra>
  </nz-page-header>
</div>

<nz-table
  #usrs
  [nzData]="Userlist"
  nzSize="small"
  nzTableLayout="auto"
  [nzFrontPagination]="false"
  *ngIf="Userlist"
>
  <thead>
    <tr>
      <th>#</th>
      <th>Овог</th>
      <th>Нэр</th>
      <th>Утас</th>
      <th>Эмэйл</th>
      <th nzWidth="70px">Нэвт.нэр</th>
      <th nzWidth="70px">Нууц үг</th>
      <th>Зураг</th>
      <th></th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let usr of usrs.data; let i = index">
      <td>{{ i + 1 }}</td>
      <td>{{ usr.ovog }}</td>
      <td>{{ usr.ner }}</td>
      <td>{{ usr.utas }}</td>
      <td>{{ usr.email }}</td>
      <td>{{ usr.username }}</td>
      <td>{{ usr.password }}</td>
      <td>
        <nz-avatar
          nzShape="square"
          [nzSize]="64"
          [nzIcon]="'user'"
          nzSrc="https://mglapi.amar.ac:35/uploads/{{ usr?.zurag }}"
          id="zoom"
        ></nz-avatar>
      </td>
      <td>
        <nz-button-group nzSize="small">
          <button nz-button nzType="primary" class="editbtn">
            <i nz-icon nzType="edit" nz-tooltip nzTooltipTitle="Засварлах"></i>
          </button>
          <button nz-button nzType="primary" class="editbtn">
            <i
              nz-icon
              nzType="arrow-right"
              nz-tooltip
              nzTooltipTitle="Дэлгэрэнгүй"
            ></i>
          </button>
        </nz-button-group>
      </td>
    </tr>
  </tbody>
</nz-table>
<div class="spin" *ngIf="!Userlist">
  <nz-spin nzSimple></nz-spin>
</div>

<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzWidth]="400"
  [nzVisible]="newusrcble"
  nzTitle="+ Шинэ Хэрэглэгч"
  [nzFooter]="footerTpl"
  (nzOnClose)="closeNewcust()"
>
  <div nz-row [nzGutter]="4">
    <div nz-col nzSpan="24">
      <div nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label>Нэр</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              placeholder="Байгууллага нэр..."
              (focus)="slctfocus($event)"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-col nzSpan="24">
      <div nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label>Регистер</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              placeholder="Байгууллага регистер..."
              (keypress)="numberOnly($event)"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-col nzSpan="24">
      <div nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label>Хаяг</nz-form-label>
          <nz-form-control>
            <textarea nz-input rows="2" (focus)="slctfocus($event)"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-col nzSpan="24">
      <div nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label>Банк, данс</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              placeholder="Хаан банк-5050505050"
              (focus)="slctfocus($event)"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-col nzSpan="24">
      <div nz-form nzLayout="vertical">
        <nz-form-control [nzSpan]="14">
          <input nz-input name="email" type="email" id="email" />
        </nz-form-control>
        <nz-form-item>
          <nz-form-label [nzSpan]="6" nzFor="email">E-mail</nz-form-label>
          <nz-form-control [nzSpan]="14">
            <input nz-input name="email" type="email" id="email" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label>Утас</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              placeholder="99999999, 88888888, 91919191"
              (focus)="slctfocus($event)"
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </div>
  <ng-template #footerTpl>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" [nzLoading]="loadingBtn">
        <i nz-icon [nzType]="loadingBtn ? 'loading' : 'save'"></i>Хадгалах
      </button>
    </div>
    <div style="float: left">
      <button
        nz-button
        nzShape="round"
        style="margin-right: 8px"
        (click)="closeNewcust()"
      >
        Хаах
      </button>
    </div>
  </ng-template>
</nz-drawer>
