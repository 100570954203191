import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  confirmModal?: NzModalRef;
  isVisible = true;
  isConfirmLoading = false;
  logDta: any = {
    username: '',
    password: '',
  };

  usrlst: any;
  password: boolean = false;
  lggedusr: any;
  buruu: boolean = false;

  constructor(
    private usrSrvce: UserService,
    private modal: NzModalService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.chkLgdUsr();
    this.usrSrvce.getUserrol().subscribe((rslt) => (this.usrlst = rslt));
  }

  handleOk(): void {
    this.isConfirmLoading = true;

    if (
      this.usrlst.filter(
        (rst: any) =>
          rst.username == this.logDta.username &&
          rst.password == this.logDta.password
      ).length > 0
    ) {
      this.lggedusr = this.usrlst.filter(
        (rst: any) =>
          rst.username === this.logDta.username &&
          rst.password === this.logDta.password
      )[0];
      this.usrSrvce.lggedUsr = this.lggedusr;

      console.log('nevtersen');
      // this.nevtrsen = true;
      localStorage.setItem('amar', JSON.stringify(this.lggedusr));
      this.router
        .navigateByUrl('/achaaburt', { skipLocationChange: true })
        .then(() => {
          this.router.navigate(['/achaaburt']);
          this.isVisible = false;
          // this.nevtrsen = true;
        });

      //   if (this.lggedusr.rolid == 1 ) {

      //     this.router.navigateByUrl('/sales', {skipLocationChange: true}).then(() => {
      //       this.router.navigate(['/sales']);
      //       // this.nevtrsen = true;
      //   });

      //     //this.router.navigate(['/borlulalt'])
      //    // this.router.navigateByUrl('/red-pill;x=white-rabbit/neo');

      //   } else {
      //   this.password = true;

      // }
    } else {
      this.buruu = true;
      this.isConfirmLoading = false;
    }
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  showLogin() {
    this.isVisible = true;
  }

  // checkUsr() {
  //   if (this.usrlst.filter(rst => rst.username == this.logDta.username && rst.password == this.logDta.password).length > 0) {

  //     this.lggedusr = this.usrlst.filter(rst => rst.username === this.logDta.username && rst.password === this.logDta.password)[0];
  //     this.usrSrvce.lggedUsr = this.lggedusr;
  //     console.log('nevtersen');
  //     // this.nevtrsen = true;
  //     localStorage.setItem('user', JSON.stringify(this.lggedusr));

  //     if (this.lggedusr.rolid == 1 ) {

  //       this.router.navigateByUrl('/borlulalt', {skipLocationChange: true}).then(() => {
  //         this.router.navigate(['/borlulalt']);
  //         // this.nevtrsen = true;
  //     });

  //       //this.router.navigate(['/borlulalt'])
  //      // this.router.navigateByUrl('/red-pill;x=white-rabbit/neo');

  //     } else {
  //     console.log('hereglegch baihgui bna');
  //     document.querySelector('#wrngtxt').innerHTML = 'Нэвтрэх нэр, Нууц үг буруу!';
  //     this.password = true;

  //   }
  // }

  // }

  chkLgdUsr(): void {
    if (this.getUser()) {
      this.lggedusr = this.getUser();
      if (this.lggedusr) {
        console.log(this.lggedusr);
        this.isVisible = false;
        this.router.navigate(['/ilgeemj']);
      } else {
        this.router.navigate(['/nevtreh']);
      }
    }
  }

  getUser(): any {
    const item = window.localStorage.getItem('amar');
    return item ? JSON.parse(item) : null;
  }

  inpt() {
    this.buruu = false;
  }
}
