<div class="site-page-header-ghost-wrapper">
  <nz-page-header [nzGhost]="false" style="background-color: #fae2a0; border-top-right-radius: 10px; border-top-left-radius: 10px;">
    <nz-page-header-title>Эрээн боомтод ирсэн ачаанууд</nz-page-header-title>
    <!-- <nz-page-header-subtitle>This is a subtitle</nz-page-header-subtitle> -->
    <nz-page-header-extra style="display: flex;/* justify-content: flex-end; */">
      <nz-page-header-title style="color:dimgrey">Бараа тоо: {{ilgemjlst?.length| number: '1.0-0'}}</nz-page-header-title>



    </nz-page-header-extra>
  </nz-page-header>
</div>

<div class="inner-content">
  <div nz-row [nzGutter]="{ xs: 4, sm: 6, md: 8, lg: 10 }">
    <div nz-col class="gutter-row" [nzSpan]="6"><div class="inner-box">
      <form nz-form nzLayout="vertical">
      <nz-form-item>
        <nz-form-label >Бар код</nz-form-label>
        <nz-form-control>
          <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
            <input type="text" nz-input placeholder="Бар кодоор хайх" [(ngModel)]="hailt" name="hailt"/>
          </nz-input-group>
          <ng-template #suffixIconButton>
            <button nz-button nzType="primary"><i nz-icon nzType="search"></i></button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      </form>
    </div>
  </div>


    <div nz-col class="gutter-row" [nzSpan]="2">
      <div class="inner-box">
      <form nz-form nzLayout="vertical">
        <nz-form-item>
          <nz-form-label></nz-form-label>
          <nz-button-group [nzSize]="'default'">
            <!-- <button nz-button nzType="primary" nz-tooltip nzTooltipTitle="PDF татах"><i nz-icon nzType="file-pdf"></i></button>
            <button nz-button nzType="primary" nz-tooltip nzTooltipTitle="Excel татах"><i nz-icon nzType="file-excel"></i></button> -->
          </nz-button-group>
        </nz-form-item>
        </form>
      </div>
    </div>




  </div>
  <nz-table #ilgemjlst2 [nzData]="findbycode()" nzSize="small" nzTableLayout="auto" [nzFrontPagination]="true" [nzPageSize]="20">

    <thead>
      <tr>
        <th nzWidth="5%">#</th>
        <th nzWidth="15%">Бар код</th>
        <th nzWidth="8%">Огноо#</th>

        <th>Тайлбар</th>

        <!-- <th>Төлсөн</th> -->
        <!-- <th>Үлдэгдэл</th>
        <th>Ачуулсан</th> -->
        <th></th>

      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let item of ilgemjlst2.data let i = index">
        <td>{{i+1}}</td>

        <td>{{item.barcode}}</td>
        <td>{{item.ognoo | date: 'yyyy/MM/dd'}}</td>

        <td>{{item.note}} </td>

        <td>
          <nz-button-group nzSize="small">

            <!-- <button nz-button nzType="primary" class="editbtn"><i nz-icon nzType="delete" nz-tooltip nzTooltipTitle="Устгах"></i></button> -->
          </nz-button-group>
      </td>
      </tr>
    </tbody>
  </nz-table>
  <!-- <div class="spin" *ngIf="!ilgemjlst">
    <nz-spin nzSimple></nz-spin>
  </div> -->
</div>










